import React from 'react';
import { connect } from 'react-redux';
import { getImages } from "../../../redux/actions";
import Loader from "../../elements/Loader";
import ImageCustomCarousel from './../../elements/ImageCustomCarousel'; // Import your custom carousel component

function AutosCarousel(props) {
  return (
    <>
      <div className="carousel-outer">
        {props.getImagesSuccess?.images ? (
          <ImageCustomCarousel
          zoomEffect={true}
          customThumnailColor={'#fcac32'}
            images={props.getImagesSuccess?.images.map((img) => `${process.env.REACT_APP_IMAGES_ADRESS}/${img.image_name}`)} // Pass the image URLs as the `images` prop
          />
        ) : (
          <Loader
            width={"40px"}
            height={"40px"}
            iconColor={"white"}
            backgroundColor={"#deb726"}
            left={"50%"}
            top={"300px"}
          />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { loading, autosImagesResponse } = state.AutosHandler;
  return {
    loading,
    getImagesSuccess: autosImagesResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getImages: (id) => dispatch(getImages(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AutosCarousel);
