import React, { useState, useEffect } from 'react';
import '../../css/customCarousel.css'; // Import the CSS file

function ImageCustomCarousel({ images,zoomEffect, customThumnailColor }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [isZoomed, setIsZoomed] = useState(false); // To track zoom state
  const [zoomedImage, setZoomedImage] = useState(null); // Store zoomed image for side menu
  const [fadeIn, setFadeIn] = useState(false); // Fade-in state for image transition
  const [isMobile, setIsMobile] = useState(false); // To detect if it's mobile

  // Detect if the device is mobile
  useEffect(() => {
    const mainImage = document.querySelector('.mainImage'); // Target the main image element
  
    // Add the touchmove event listener
    if (mainImage) {
      mainImage.addEventListener('touchmove', handleTouchMove, { passive: false });
    }
  
    // Clean up the event listener
    return () => {
      if (mainImage) {
        mainImage.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [isZoomed]); // Re-run if zoom mode changes

  const handleNext = () => {
    if (images.length <= 1) return; // Prevent navigation if there's only one image
    setFadeIn(false); // Trigger fade out
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length); // Change image after fade out
    }, 300); // Wait for fade-out transition to complete (300ms)
  };

  const handlePrev = () => {
    if (images.length <= 1) return; // Prevent navigation if there's only one image

    setFadeIn(false); // Trigger fade out
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      ); // Change image after fade out
    }, 300); // Wait for fade-out transition to complete (300ms)
  };

  // Handle mouse move for zoom (non-mobile devices)
  const handleMouseMove = (e) => {
    if (!isZoomed) return;
  
    const target = e.target; // Image being zoomed
    const { top, left, width, height } = target.getBoundingClientRect();
  
    // Adjust for scroll offset
    const scrollY = window.scrollY || document.documentElement.scrollTop;
  
    // Calculate correct zoom position
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - (top + scrollY)) / height) * 100;
  
    setZoomPosition({ x, y });
  };
  
  const handleTouchMove = (e) => {
    if (!isZoomed) return; // Only allow preventDefault if zoom mode is active
  
    e.preventDefault(); // Prevent the default touch behavior (e.g., scrolling)
  
    const touch = e.touches[0];
    const target = e.target;
    const { top, left, width, height } = target.getBoundingClientRect();
  
    // Adjust for scroll offset
    const scrollY = window.scrollY || document.documentElement.scrollTop;
  
    const x = ((touch.pageX - left) / width) * 100;
    const y = ((touch.pageY - (top + scrollY)) / height) * 100;
  
    setZoomPosition({ x, y });
  };

  const handleMouseLeave = () => {
    if (!isZoomed) return; // Only stop zooming when in zoom mode
    setZoomedImage(null); // Clear zoomed image when mouse leaves
    setIsZoomed(false); // Disable zoom mode
  };

  const handleImageClick = () => {
    setIsZoomed(true); // Enable zoom mode when image is clicked
    setZoomedImage(images[currentIndex]); // Set the clicked image for zoom view
  };

  const handleThumbnailClick = (index) => {
    if (index === currentIndex) {
      // Prevent image disappearing if the same thumbnail is clicked
      return; // Don't trigger fade out if the same image is clicked
    }

    setFadeIn(false); // Trigger fade-out before changing image
    setTimeout(() => {
      setCurrentIndex(index); // Change image after fade out
    }, 300); // Wait for fade-out transition to complete (300ms)
  };

  // Trigger fade-in after the image changes
  useEffect(() => {
    setFadeIn(true);
  }, [currentIndex]);

  return (
    <div className="carouselContainer">
      <div className="carouselWrapper">
        {/* Main Image */}
        <div className="mainImageWrapper">
          <img
            src={images[currentIndex]}
            alt={`Image ${currentIndex + 1}`}
            onMouseMove={!isMobile ? handleMouseMove : null} // Only apply mousemove on non-mobile
            onTouchMove={isMobile ? handleTouchMove : null} // Apply touchmove for mobile devices
            onMouseLeave={handleMouseLeave}
            onClick={handleImageClick} // Trigger zoom effect on image click
            className="mainImage"
            style={{
              opacity: fadeIn ? 1 : 0, // Apply fade-in effect
              transition: 'opacity 0.3s ease-in-out', // Smooth transition for image fade
            }}
          />

          {/* Navigation Buttons */}
          <button onClick={handlePrev} className="navButtonLeft">
            ◀
          </button>
          <button onClick={handleNext} className="navButtonRight">
            ▶
          </button>
        </div>

        {/* Thumbnail Navigation */}
        <div className="thumbnailWrapper">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => handleThumbnailClick(index)}
              className="thumbnail"
              style={{
                border: index === currentIndex ? `3px solid ${customThumnailColor}` : 'none',
              }}
            />
          ))}
        </div>
      </div>

      {/* Side Zoom View (when zoom is active) */}
      {isZoomed && zoomedImage && zoomEffect && (
        <div className="sideZoomViewWrapper">
          <div
            className="sideZoomView"
            style={{
              backgroundImage: `url(${zoomedImage})`,
              backgroundPosition: `${zoomPosition.x}% ${zoomPosition.y}%`,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ImageCustomCarousel;
