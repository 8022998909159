import * as React from "react";
import ReactToPrint from "react-to-print";
import Loader from "../../elements/PrintLoader";
import { connect } from 'react-redux';
import Swal from "sweetalert2";

import {

  ContainerInvoiceDelete,
  invoicePrint,
  getContainerInvoices,
  hideContainerInvoiceInfoEditModal,
  showContainerInvoiceInfoEditModal,
  ContainerInvoiceEditInfo,
  showContainerInvoiceBillerEditModal,
  hideContainerInvoiceBillerEditModal,
  ContainerInvoiceUpdateInfo,
  ContainerInvoiceEditBillerInfo,
  ContainerInvoiceBillerUpdateInfo,
  ContainerInvoiceOriginDelete
 
} from "../../../redux/actions";
import  {BillToPrint}  from "./ContainerBill";
import {can} from './../../utils/roleUtils';

const ContainerBillView = (props) => {
  const componentRef = React.useRef(null);

  const onBeforeGetContentResolve = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("old boring text");

  const handleAfterPrint = React.useCallback(() => {
    // console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    // console.log("`onBeforePrint` called");
  }, []);



  // const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;
  const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;



  const handleOnBeforeGetContent = React.useCallback(() => {
    // console.log("`onBeforeGetContent` called");
    props.invoicePrint(props.containerID);
    setLoading(true);
    setText("Loading new text...");

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setLoading(false);
        setText("New, Updated Text!");
        resolve();
      }, 2000);
    });
  }, [setLoading, setText]);

  React.useEffect(() => {
    if (
      text === "New, Updated Text!" &&
      typeof onBeforeGetContentResolve.current === "function"
    ) {
      onBeforeGetContentResolve.current();
    }
  }, [onBeforeGetContentResolve.current, text]);


  // React.useEffect(()=>{
  //   if(props.containerInvoiceResponse){
  //   props.getContainerInvoices(props.containerInvoiceResponse?.ContainerDetail?.id);
  //   }
  // },[props.containerDeleteInfoBill])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);





  const deleteInvoice=(id)=>{



    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Container Invoice",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
    props.ContainerInvoiceOriginDelete(props.containerInvoiceResponse?.invoice_info?.realID)
        }
    });
    
    
    
      }



  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return(<div>
    <div style={{height:'10px'}}></div>
     <span><button className="btn-print">Print</button> </span> 
     </div>);
  }, []);

  return (
    <div>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle={props.containerInvoiceResponse?.ContainerDetail?.lot}
        onAfterPrint={handleAfterPrint}
        onBeforeGetContent={handleOnBeforeGetContent}
        onBeforePrint={handleBeforePrint}
        removeAfterPrint
        trigger={reactToPrintTrigger}
      />
      {loading &&
      <div>
        <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            />
            </div>}
           {can(currentUserPermissions,'Delete Container Invoice')? <span><button className="btn-delete-invoice" onClick={()=>deleteInvoice()}>Delete Invoice</button> </span>:""}


      <div style={{height:'14px'}}></div>
      <BillToPrint ref={componentRef} 
      responseContainerInvoice={props.containerInvoiceResponse} 
      invoiceInfoDelete={props.ContainerInvoiceDelete} 
      getContainerInvoices={props.getContainerInvoices} 
      containerDeleteInfoBill={props.containerDeleteInfoBill} 
      ID={props.containerID} 
      containerPrintResponse={props.containerPrintResponse}
      showEditInfo={props.showContainerInvoiceInfoEditModal} 
      hideEditInfo={props.hideContainerInvoiceInfoEditModal}
      openInvoiceEditInfo={props.openInvoiceEditInfo} 
      invoiceEditInfo={props.invoiceEditInfo}
      ContainerInvoiceEditInfo={props.ContainerInvoiceEditInfo}
      SelectorsFetch={props.SelectorsFetch}
      openInvoiceEditBillerResponse={props.openInvoiceEditBiller}
      showContainerInvoiceBillerEditModal={props.showContainerInvoiceBillerEditModal}
      hideContainerInvoiceBillerEditModal={props.hideContainerInvoiceBillerEditModal}
      ContainerInvoiceUpdateInfo={props.ContainerInvoiceUpdateInfo}
      invoiceInfoUpdationResponse={props.invoiceInfoUpdationResponse}
      ContainerInvoiceEditBillerInfo={props.ContainerInvoiceEditBillerInfo}
      invoiceBillerEditResponse={props.invoiceBillerEditResponse}
      ContainerInvoiceBillerUpdateInfo={props.ContainerInvoiceBillerUpdateInfo}
      invoiceBillerUpdateResponse={props.invoiceBillerUpdateResponse}
      preDataLoadResponse={props.preDataLoadResponse}
      />
    </div>
  );
};


const mapStateToProps = (state) => {
  const {containerInvoiceResponse,loading,containerDeleteInfoBill,containerPrintResponse,openInvoiceEditInfo,invoiceEditInfo,openInvoiceEditBiller,invoiceInfoUpdationResponse,invoiceBillerEditResponse,invoiceBillerUpdateResponse} = state.ContainerInvoiceHandler;
  const { SelectorsFetch } = state.SelectorsHandler;
  const { preDataLoadResponse } = state.preDataHandler;


  return {
    loading: loading,
    containerInvoiceResponse:containerInvoiceResponse,
    containerDeleteInfoBill:containerDeleteInfoBill,
    containerPrintResponse:containerPrintResponse,
    openInvoiceEditInfo:openInvoiceEditInfo,
    invoiceEditInfo:invoiceEditInfo,
    SelectorsFetch:SelectorsFetch,
    openInvoiceEditBiller:openInvoiceEditBiller,
    invoiceInfoUpdationResponse:invoiceInfoUpdationResponse,
    invoiceBillerEditResponse:invoiceBillerEditResponse,
    invoiceBillerUpdateResponse:invoiceBillerUpdateResponse,
    preDataLoadResponse: preDataLoadResponse,


    
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    ContainerInvoiceUpdateInfo:(id,invoiceCatory,notes,amount)=>dispatch(ContainerInvoiceUpdateInfo(id,invoiceCatory,notes,amount)),
    showContainerInvoiceInfoEditModal:()=>dispatch(showContainerInvoiceInfoEditModal()),
    hideContainerInvoiceInfoEditModal:()=>dispatch(hideContainerInvoiceInfoEditModal()),
    showContainerInvoiceBillerEditModal:()=>dispatch(showContainerInvoiceBillerEditModal()),
    hideContainerInvoiceBillerEditModal:()=>dispatch(hideContainerInvoiceBillerEditModal()),


    invoicePrint:(id)=>dispatch(invoicePrint(id)),
    ContainerInvoiceDelete:(id)=>dispatch(ContainerInvoiceDelete(id)),
    getContainerInvoices:(id)=>dispatch(getContainerInvoices(id)),
    ContainerInvoiceEditInfo:(id)=>dispatch(ContainerInvoiceEditInfo(id)),
    ContainerInvoiceEditBillerInfo:(id)=>dispatch(ContainerInvoiceEditBillerInfo(id)),
    ContainerInvoiceBillerUpdateInfo:(id,biller)=>dispatch(ContainerInvoiceBillerUpdateInfo(id,biller)),
    ContainerInvoiceOriginDelete:(id)=>dispatch(ContainerInvoiceOriginDelete(id))
  };
};





export default connect(mapStateToProps,mapDispatchToProps)(ContainerBillView);