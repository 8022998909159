import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import '../../../css/searchBox.css'
import "../../../css/containers.css";
import Swal from "sweetalert2";
import Loader from "../../elements/Loader";
import CloseButton from "react-bootstrap/esm/CloseButton";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import ImagesGrid from "../autos/AutoImagesGrid";
import { connect } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import ProgressBar from "react-bootstrap/ProgressBar";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Logo from "../../assets/normLogo.png";
import _ from "lodash";
import NodataFound from '../../pages/error/noDataFoundMain';
import { useTranslation } from 'react-i18next'

import { AsyncPaginate } from "react-select-async-paginate";
import Pagination from "react-js-pagination";
import ContainerImagesCarousel from '../../pages/containers/ContainerImagesCarousel'
import Checkbox from '../../elements/Checkbox';
import ContainerImagesGrid from '../containers/ContainerImagesGrid'
import BillView from '../../pages/containers/ContainerBillView';

import {
  getSelectors,
  hideContainerEditModal,
  showContainerEditModal,
  showContainerRegModal,
  hideContainerRegModal,
  VinNumberFetchForContainers,
  ContainerRegistration,
  ContainersList,
  getContainerImages,
  downloadImagesContainerZip,
  ContainerDelete,
  showContainerAutoAdditionModal,
  hideContainerAutoAdditionModal,
  containerAddAutos,
  selectedAutosInContainersDelete,
  getContainerDetails,
  ContainerUpdate,
  storeImagesContainer,
  selectedContainersDelete,
  searchContainersList,
  inputHandler,
  toggleContainerInvoiceModal,
  registerContainerInvoice,
  invoiceInformationModalToggle,
  getContainerInvoices,

} from "../../../redux/actions";

import { loadVins } from "../../utils/asyncOptionsUtils";
import { billCatagory } from "../../utils/permissionsUtils";

import {can} from '../../utils/roleUtils';

function ContainerList(props) {

  const [onId, setOnId] = useState(null);
  const [error, setError] = useState('');

  const [header, setHeader] = useState("all-headers");
  const [value, onChange] = useState([]);
  const [autosInContainer, setAutosInContainer] = useState([]);

  const [fileName, setFileName] = useState({dock_receipt:'Choose Dock Receipt',bl_copy:'Choose BL Copy',telex_release:'Choose Telex Release',validations:'Choose Validations'});
  const [searched, setSearched] = useState(false);


  const [isCheckAll, setIsCheckAll] = useState(false);   
const [isCheck, setIsCheck] = useState([]);
const [isCheckedContainer, setIsCheckContainer] = useState([]);

  const [dragAndDropTextImages, setdragAndDropTextImages] = useState(
    "Upload or Drag and Drop images here"
  );
  const [dragAndDropSelected, setdragAndDropSelected] = useState(false);

  const [
    dragAndDropAdditionalInvoiceText,
    setdragAndDropAdditionalInvoiceText,
  ] = useState("Upload or Drag and Drop your Invoice here");
  const [
    dragAndDropAdditionalInvoiceSelected,
    setdragAndDropAdditionalInvoiceSelected,
  ] = useState(false);

  const [dragAndDropSelectedImages, setdragAndDropSelectedImages]=useState(false);
  const [imagesModal, setImagesModal] = useState(false);
  const [vinsFieldRequired, setVinsFieldRequired] = useState(false);


  const [autoAdditionalDataError, setAutoAdditionalDataErrors] = useState({
    auto_catagory_error: "",
  });

  const [containerRegData, setcontainerRegData] = useState({
    onId: "",
    container_number: "",
    images: [],
    booking_number: "",
    lines: null,
    dock_receipt: null,
    bill_copy: null,
    telex_release: null,
    port: "",
    loading_date: null,
    expected_arrival_date: null,
    container_arrival_date: null,
    container_unloading_date:null,
    validations: null,
    point_of_loading:"",
  });

  const [autoRegistrationData, setAutoRegistrationData] = useState({
    vin: "",
    lot: "",
    autoType: null,
    model: "",
    make: "",
    year: "",
    color: "",
    purchase_date: new Date(),
    auction: null,
    city: null,
    buyer_no: null,
    payment_to_auction: new Date(),
    port: null,
    point_of_loading: null,
    customer_notes: "",
    dismantle: "false",
    self_delivered: "false",
    invoice: null,
    auto_price: "",
  });

  const listenScrollEvent = () => {
    if (window.scrollY > 134) {
      return setHeader("all-headers-fixed");
    } else if (window.scrollY < 80) {
      return setHeader("all-headers");
    }
  };

  useEffect(() => {

    window.removeEventListener("scroll", listenScrollEvent);

    window.addEventListener("scroll", listenScrollEvent);

    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  

  useEffect(() =>{
    // props?.getSelectors();
    
    props?.ContainersList(props.currentPage);


if(props.contAutosRegResponse){

    setcontainerRegData({...containerRegData,images:[]})
  
  
          setdragAndDropTextImages('Upload or Drag and Drop images here')
          setcontainerRegData({...containerRegData,images:[]})
          setdragAndDropSelectedImages(false)
}


if(props.contAutosRegResponse){
  if(props.nullValues){
    onChange([])
  setcontainerRegData(
  {
    onId: "",
    container_number: "",
    images: [],
    booking_number: "",
    lines: null,
    dock_receipt: null,
    bill_copy: null,
    telex_release: null,
    port: "",
    loading_date: null,
    expected_arrival_date: null,
    container_arrival_date: null,
    validations: null,
    point_of_loading:"",
  }
  )
}
}

    if( props.contAddAutosResponse){
      if(props.nullValues){
        setAutosInContainer([]);
      }
    }

     
    if( props.containerSelectedAutosDeleteResponse){
      if(props.nullValues){
        setIsCheck([])
      }
    }

    if(props?.selectedDeletionContainerResponse){
      setIsCheckContainer([]);
    }


  },[props.containerDeleteResponse,props.contAutosRegResponse, props.contAddAutosResponse,props.containerSelectedAutosDeleteResponse,props.containerUpdateResponse,props.selectedDeletionContainerResponse,props.containerImagesUploadResponse,props.containerImagesDeletionResponse])




  useEffect(()=>{
    if(props.containerImagesUploadResponse){
      if(props.nullValues){
  
        setcontainerRegData({...containerRegData,images:[]})
  
  
          setdragAndDropTextImages('Upload or Drag and Drop images here')
          setcontainerRegData({...containerRegData,images:[]})
          setdragAndDropSelectedImages(false)
      }
    
    }
  },[props.containerImagesUploadResponse])



useEffect(()=>{
  props?.getSelectors();
},[])

useEffect(()=>{


  if(props.containerDetailsResponse){
    let containerInfo=props.containerDetailsResponse?.containerInfo;
    setcontainerRegData({...containerRegData,
      port: containerInfo?.cont_port?{value:containerInfo?.cont_port, label:containerInfo?.cont_port}:'',
      onId:containerInfo?.id,

      container_number:containerInfo?.container_no,
      booking_number:containerInfo?.cont_booking_no,
      lines:containerInfo?.cont_line,
      loading_date:containerInfo?.cont_date_loading!=null?moment(containerInfo?.cont_date_loading).toDate():null,
      expected_arrival_date:containerInfo?.cont_expect_date_arrival!=null?moment(containerInfo?.cont_expect_date_arrival).toDate():null,
      container_arrival_date:containerInfo?.cont_arrival_date!=null?moment(containerInfo?.cont_arrival_date).toDate():null,
      container_unloading_date:containerInfo?.cont_unloading_date!=null?moment(containerInfo?.cont_unloading_date).toDate():null,

      point_of_loading: containerInfo?.cont_point_loading?{value:containerInfo?.cont_point_loading, label:containerInfo?.cont_point_loading}:'',
    })

setFileName(  {...fileName, 
   telex_release:containerInfo?.cont_telex_release?.substring(13,20).concat('...')||'Choose Telex Release',
   bl_copy:containerInfo?.cont_bill_copy?.substring(13,20).concat('...')||'Choose BL Copy',
   dock_receipt:containerInfo?.cont_doc_receipt?.substring(13,20).concat('...')||'Choose Dock Receipt',
   validations:containerInfo?.cont_validation?.substring(13,20).concat('...')||'Choose Validations',

}
   )


// let  carsInContainer= props.containerDetailsResponse?.autosInContainer;

//   let carsVins;
//   if (carsInContainer) {
//     carsVins = carsInContainer.map(function (data) {
//       return { value:data.autoId, label:`${data.vin} | ${data.userName}` };
//     });
//   }
//   onChange(carsVins);


  }




},[props.containerDetailsResponse])

const { t } = useTranslation() 


  const handleChangeImages = (FileList) => {
    // console.log(FileList);
    if (FileList.length > 0) {
      setdragAndDropSelectedImages(true);
      if (FileList.length == 1) {
        setdragAndDropTextImages(`${FileList.length} image`);
      } else {
        setdragAndDropTextImages(`${FileList.length} images`);
      }
      const imagesArray = [];

      for (let i = 0; i < FileList.length; i++) {
        imagesArray.push(FileList[i]);
      }

      setcontainerRegData({ ...containerRegData, images: imagesArray });
    } else {
      setdragAndDropSelectedImages(false);
    }
  };

  const onstoreContainerData = (e) => {
    e.preventDefault();
  //here i have to use the vin number issue.s
  if(value?.length !=0 ){
      props.ContainerRegistration(
        containerRegData,
        value,
        props?.containersListResponse?.data?.current_page
        );

        setVinsFieldRequired(false)
      }else{
        setVinsFieldRequired(true)

      }
  };

  const onUpdateContainerData = (e) => {
    e.preventDefault();
  
      props.ContainerUpdate(
        containerRegData,
        value,
        props?.containersListResponse?.data?.current_page
        
        );

  };


  const onInputChangeAdditional = (e) => {
    setcontainerRegData({
      ...containerRegData,
      [e.target.name]: e.target.value,
    });
  };





  const handleAttachment=(e)=>{
    // console.log(e)
    if(e.target?.files[0]?.name==undefined || e.target?.files[0]==undefined ){
      setcontainerRegData({...containerRegData, [e.target.name]:null})
      setFileName({...fileName,telex_release:'Choose Telex Release',dock_receipt:'Choose Dock Receipt', bl_copy:'Choose BL Copy', validations:'Choose Validations'})
    }else{
      setcontainerRegData({...containerRegData,[e.target.name]:e.target?.files[0]})
      setFileName({...fileName,[e.target.name]:e.target?.files[0]?.name})

    }
  }





  const defaultAdditional = {
    page: 1
  };
  
  


  // const loadOptions = async (search, page) => {
  //   // await sleep(1000);
  //   const optionsPerPage = 6;

  //     const responseJSON = await props?.VinNumberFetchResponse?.data;
  //   // console.log(responseJSON.data)
  //   // setLoadedOptions(...loadedOptions,responseJSON?.data?.data)

  //     let options; 
  //     if(responseJSON?.data){
  //         options = responseJSON.data.map(function (data) {
  //         return { value: data.id, label: `${data.vin} | ${data.username}` }
  //       })
  //     }
  //     // inshallah will iterate it from back end
  // // console.log(options)
  //   let filteredOptions;
  //   if (!search) {
  //     filteredOptions = options;
  //   } else {
  //     const searchLower = search.toLowerCase();
  
  //     filteredOptions = options.filter(({ label }) =>
  //       label.toLowerCase().includes(searchLower)
  //     );
  //   }

  //   const hasMore = responseJSON.last_page > page;

  //   const slicedOptions = filteredOptions.slice(
  //     (page - 1) * optionsPerPage,
  //     page * optionsPerPage
  //   );
  //   // console.log(page)
  //   return {
  //     options: slicedOptions,
  //     hasMore
  //   };

  // }

  // const loadPageOptions = async (q, prevOptions, { page }) => {
  //   props.VinNumberFetchForContainers(q)

  //   const { options, hasMore } = await loadOptions(q, page);
  
  //   return {
  //     options,
  //     hasMore,
  
  //     additional: {
  //       page:  page + 1,
  //     }
  //   };
  // };



  const downloadZipImages=(id)=>{


    Swal.fire({
      title: "Are you sure?",
      text: "You want to download images of container",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, download it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.downloadImagesContainerZip(id);
      }
    });
    

  }






  const deleteContainer=(id)=>{


    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this container",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.ContainerDelete(id);
      }
    });
    

  }

  

  const deleteSelectedAutosContainer=()=>{


    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete selected autos in this container",
      icon: "warning",
      width: 400,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        props.selectedAutosInContainersDelete(isCheck);
      }
    });
    

  }


  const handleSearchPages=(pageNumber)=>{
    console.log(pageNumber)
    props.searchContainersList(props.searchContainers,pageNumber)
  
  }








  const handleDateInputChange = (e, dateSelectorName) => {
    // let formated = moment(e).format('YYYY-MM-DD')
    setAutoRegistrationData({ ...autoRegistrationData, [dateSelectorName]: e });
  };

  const handleDateInputChangeContainer = (e, dateSelectorName) => {
    // let formated = moment(e).format('YYYY-MM-DD')
    setcontainerRegData({ ...containerRegData, [dateSelectorName]: e });
  };



  const handleMoreInfoSelectors = (e, selectorName) => {
    setAutoAdditionalDataErrors({ auto_catagory_error: "" });
    // console.log(autoRegistrationData)
    let selectName = selectorName.name.toString();
    setcontainerRegData({ ...containerRegData, [selectName]: e });
  };

  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 40,
      baseUnit: 4,
    },
  });

  const menuPortal = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const titleStatus = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    { value: "BOS (exportable)", label: "BOS (exportable)" },
  ];

  // ==========================************================
  // All selectors options
  // ==========================************================

 


  let landingPointOptions;
  if (props?.SelectorsFetch?.warehouse) {
    landingPointOptions = props.SelectorsFetch?.warehouse.map(function (data) {
      return { value: data.warehouse_name, label: data.warehouse_name };
    });
  }

  let portOptions;
  if (props?.SelectorsFetch?.port) {
    portOptions = props.SelectorsFetch?.port.map(function (data) {
      return { value: data.port_name, label: data.port_name };
    });
  }


  let billersOptions; 
if(props?.SelectorsFetch?.billers){
  billersOptions = props.SelectorsFetch?.billers.map(function (data) {
    return { value: data.id, label: data.biller_name }
  })
}
 

  const handleRegContainerClose = () => {
    setcontainerRegData({ ...containerRegData, invoice: null });
    setdragAndDropAdditionalInvoiceSelected(false);
    setdragAndDropAdditionalInvoiceText(
      "Upload or Drag and Drop your images here"
    );
    setcontainerRegData({ ...containerRegData, images: [] });

    setdragAndDropSelectedImages(false);
    setdragAndDropTextImages("Upload or Drag and Drop your images here");
    props.hideContainerRegModal();

    setAutoAdditionalDataErrors({ auto_catagory_error: "" });
  };

  const handleChangeAdditional = (file) => {
    if (_.isEmpty(file)) {
      setdragAndDropAdditionalInvoiceSelected(true);
      setdragAndDropAdditionalInvoiceText(file.name);
      setcontainerRegData({ ...containerRegData, invoice: file });
    } else {
      setdragAndDropAdditionalInvoiceSelected(false);
    }
  };

  const handleEditContainerClose=()=>{
    setcontainerRegData({
      onId: "",
      container_number: "",
      images: [],
      booking_number: "",
      lines: null,
      dock_receipt: null,
      bill_copy: null,
      telex_release: null,
      port: "",
      loading_date: null,
      expected_arrival_date: null,
      container_arrival_date: null,
      validations: null,
    })
    props.hideContainerEditModal()
  }

const handleAddAutoInContainerClose=()=>{
props.hideContainerAutoAdditionModal()
}

const addAutosInContainer=(e)=>{
  e.preventDefault();
  if(autosInContainer.length > 0){
props.containerAddAutos(onId,autosInContainer,props?.containersListResponse?.data?.current_page)
  }else{
    setError('Please, select any vin of the auto');
  }

}


const onSearch=(e)=>{
  e.preventDefault();
  
    if(props.searchContainers){
  setSearched(true);
    props.searchContainersList(props.searchContainers,props.currentPage)
    }else{
      setSearched(false);
     
  
    }
  
  }


// search function
const onSearchInputChange=(e)=>{
  if(e.target.value=='' && searched ){
    
    props?.ContainersList(props.currentPage);
    setSearched(false);

  }
  else{
    props.inputHandler({ key: e.target.name, value: e.target.value });
   
}
  
}





// const handleSelectAll = e => {
//   setIsCheckAll(!isCheckAll);
//   setIsCheck(props.containersListResponse?.containerAutos?.map(li => li.id));
//   if (isCheckAll) {
//     setIsCheck([]);
//   }
// };

const handleClick = e => {
  const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
 
};

const handleClickCheckContainer = e => {
  const { id, checked } = e.target;
  setIsCheckContainer([...isCheckedContainer, id]);
    if (!checked) {
      setIsCheck([]);
      setIsCheckContainer(isCheckedContainer.filter((item) => item !== id));
    }
 
};


const uploadImages = () =>{

  props.storeImagesContainer(containerRegData)

}


// const billCatagory = [
//   { value: 'Credit', label: 'Credit' },
//   { value: 'Debit', label: 'Debit' },
 
// ]
// container invoice code starts from here

const [billInfo, setBillInfo] = useState(
  { containers_id:'', bill_to:null }
);


const [billEntries, setBillEntries] = useState([
  { vin:null, invoice_catagory: '', amount: '', notes: '' },
]);



const addBillEntry = () => {
  setBillEntries([...billEntries, { vin:null, invoice_catagory: '', amount: '', notes: '' }]);
};



const removeBillEntry = (index) => {
  const updatedEntries = [...billEntries];
  updatedEntries.splice(index, 1); // Remove the entry at the specified index
  setBillEntries(updatedEntries);
};

const handleVinChange = (index, selectedVin) => {
  const updatedEntries = [...billEntries];
  updatedEntries[index].vin = selectedVin; // Set the selected VIN
  setBillEntries(updatedEntries);
};

const handleBillSelector = (e,selectorName) => {
  // console.log(autoRegistrationData)
let selectName = selectorName.name.toString();
setBillInfo({ ...billInfo, [selectName]:e });

};
const handleInputChange = (index, field, value) => {
  const updatedEntries = [...billEntries];
  updatedEntries[index][field] = value; // Update the specific field for the specified entry
  setBillEntries(updatedEntries);
};



const submitContainerInvoice=(e)=>{
  e.preventDefault();

    props.registerContainerInvoice(billInfo,billEntries)
  

  
  
  }

  useEffect(()=>{
    if(props.containerInvoiceModalToggle){
    setBillEntries([{ vin:null, invoice_catagory: '', amount: '', notes: '' }]);
    }
  },[props.containerInvoiceModalToggle])



// const onInputChangeBill=(e)=>{

//   setBillInfo({ ...billInfo, [e.target.name]:e.target.value });
  
//   }


// const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;

const currentUserPermissions = props.preDataLoadResponse?.currentUserPermissions;



  return (
    <>
      <div className="outer-container box-shadow">
        <div className={`card ${header}`}>
          <div className="row">
            <div className="col">
              <form onSubmit={onSearch}>
                <div className="row">
     

                  {/* <div className="col-md-2 ">
          <OverlayTrigger
      key={'forSearch'}
      placement={'bottom'}
      delay={{ show: 3000, hide: 0 }}
      overlay={
        TooltipSearchFor
      }
    >

            <div className="selectBox">
              <select className="searchIn" name="">
                <option disabled>Search Catagory</option>
                <option>VIN</option>
                <option>LOT</option>
              </select>
            </div>
           </OverlayTrigger>
          </div> */}
          <div className="col-md-2 "> 
          { isCheckedContainer.length>0?<span style={{position:'absolute',top:'8px',right:'60px'}}><a className="delete-all-autos" style={{textDecoration:'none'}} onClick={()=>props.selectedContainersDelete(isCheckedContainer)}>Delete ({(isCheckedContainer.length)})</a></span>:''}

           </div>
                  <div className="col-md-6 ">
                  <div className="outLineOfSearch">
                    <div className="input-group">
                   
                 
                    
                          <input
                            type="search"
                            name="searchContainers"
                            onChange={onSearchInputChange}
                            className="form-control search"
                            placeholder="Seach by Container No or Arrival Date"
                            autoComplete="off"
                            required
                          />
                      
                      
                        {/* { isCheck.length>0?<span style={{position:'absolute',top:'8px',right:'60px'}}><a className="delete-all-autos" style={{textDecoration:'none'}} onClick={()=>props.selectedAutosDelete(isCheck)}>Delete ({(isCheck.length)})</a></span>:''} */}
                    
                      <button type="submit" className="btn btn-primary">
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                  </div>



                  <div className="col-md-2 pt-2">
                  <span style={{paddingLeft:'42px'}}>{isCheck.length>0 ? <a className="delete-selected-autos" onClick={()=>{deleteSelectedAutosContainer()}}>Delete selected ({isCheck.length})</a>:''}</span>
</div>


                </div>
              </form>
            </div>
          </div>
        </div>


 {/* ========functional Loader==========   */}

 {
  props.functionalLoader?
<Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            />:''
}


        { 
  searched && <div style={{textAlign:'center',color:'white',paddingTop:'12px'}}><p>Results for <span style={{color:'orange', textTransform:'uppercase'}}>{props.searchContainers}</span></p></div>
}
        {
          props.containersListResponse?.data?.data?.length===0 && <NodataFound notFoundText={"No Data Found in the System!"} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="white" notFoundColor="#eb9e23"/> 
}
        {/* {props?.containersListResponse?.data.data.filter((filterValue)=> props?.containersListResponse.containerAutos.some((someValue)=>filterValue.id===someValue.containers_id)).map((list,index)=>{ */}
        {
         props.loading ? <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"50%"}
              top={"40%"}
            />:
          
          props?.containersListResponse?.data.data.map((list,index)=>{

          return (

<div key={index}>
        <div className="gap"></div>

        <div className="card listSection langDirection" dir={JSON.parse(localStorage.getItem('lang_dir'))} >
    {can(currentUserPermissions,'Delete Multiple Containers') ?  <div className="checkbox-container">
         
               
         <Checkbox
 key={list.id}
 type="checkbox"
 name={list.id}
 id={list.id}
 handleClick={handleClickCheckContainer}
 isChecked={isCheckedContainer.find(li=>li===list.id)}
/>
       </div>:""}


          <div className="row listSecitionInside">
            {  props.containersListResponse?.images?.filter(image => image.containers_id === list.id).map((img,ind)=>{
           return(
            <div className="col-md-2" style={{cursor:'pointer'}} key={ind}>

            <div className="imgStyle" onClick={()=>{
                 setOnId(list.id)
                 props.getContainerImages(list.id)
  setTimeout(() => {
    setImagesModal(true)
  },1000)
            }}>
                <span>
                  <span className="badge-container">{img.totalImages}</span>
               
<img alt="autos"  src={`${process.env.REACT_APP_CONTAINER_IMAGES}/${img.image_name}`} className="card-image-top"/>

                </span>
              </div>
              </div>
           )
           
            })}
             
          
            <div className="col">
              <div className="row listSecitionInside">
                <div className="col-md-3">
                  <span className="titleCont">{t('key_container_no_booking')}: </span>
                  <span>{list.container_no}</span>
                </div>
                <div className="col-md-3">
                  <span className="titleCont">{t('key_booking_no')}: </span>
                  <span>{list.cont_booking_no}</span>
                </div>
                <div className="col-md-3">
                  <span className="titleCont">{t('key_lines')}: </span>
                  <span>{list.cont_line}</span>
                </div>
            {can(currentUserPermissions,'View Dock Receipt')?    <div className="col-md-3">
                  <span className="titleCont" dir="">Dock Receipt: </span>
                  <span className=""><a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${list.cont_doc_receipt}`} target={"_blank"} dir="ltr">{list?.cont_doc_receipt? list?.cont_doc_receipt.substring(13,20).concat('...'):''}</a></span>

                </div>:""}
              </div>

              <div className="row listSecitionInside">
               { can(currentUserPermissions,'View BL Copy')?<div className="col-md-3">
                  <span className="titleCont">BL Copy: </span>{" "}
                  <span className=""><a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${list.cont_bill_copy}`} target={"_blank"} dir="ltr">{list?.cont_bill_copy? list?.cont_bill_copy.substring(13,20).concat('...'):''}</a></span>
                </div>:""}



             
                { can(currentUserPermissions,'View Telex Release')? <div className="col-md-3">
                  <span className="titleCont">Telex Release: </span>{" "}
                  <span className=""><a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${list.cont_telex_release}`} target={"_blank"} dir="ltr">{list?.cont_telex_release?list?.cont_telex_release.substring(13,20).concat('...'):''}</a></span>
                </div>:""}

                <div className="col-md-3">
                  <span className="titleCont">{t('key_port')}: </span> <span>{list.cont_port}</span>
                </div>

                
                <div className="col-md-3">
                  <span className="titleCont">{t('key_point_of_loading')}:</span><span>{list.cont_point_loading}</span>
                </div>
              </div>

              <div className="row listSecitionInside">
               

            
                <div className="col-md-3">
                  <span className="titleCont">{t('key_date_of_loading')}: </span>
                  <span>{list.cont_date_loading}</span>
                </div>
                <div className="col-md-3">
                  <span className="titleCont">{t('key_expected_arrival_date')}: </span>
                  <span>{list.cont_expect_date_arrival}</span>
                </div>

                <div className="col-md-3">
                  <span className="titleCont">{t('key_container_arrival_date')}: </span>
                  <span>{list.cont_arrival_date}</span>
                </div>

               {can(currentUserPermissions,'View Validations') ? <div className="col-md-3">
                <span className="titleCont">Validations: </span> 
                <span className=""><a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_CONTAINER_FIlES}/${list.cont_validation}`} target={"_blank"} dir="ltr">{list?.cont_validation? list?.cont_validation.substring(13,20).concat('...'):''}</a></span>

                </div>:""}


                {can(currentUserPermissions,'View Container Invoice')?
              <div>
                <span className="titleCont">{t('key_shipping_invoice')}:</span>{" "}
                
                <span className="description">
     
           <a style={{cursor:'pointer'}} onClick={()=>{
            setBillInfo({...billInfo,'containers_id':list.id})
            props.getContainerInvoices(list.id);
            setTimeout(()=>{
              props.invoiceInformationModalToggle()
            },550)
           }
           }><span className="shippingInvoiceText">{t('key_view_invoice')}</span> </a> 

                </span>
              </div>:''
}


              </div>

        

              <div className="lineContiner"></div>
          

<div className="row pt-1">
<span className="titleCont pb-2">{t('key_place_vehicles_in_container')} </span> 
                  {/* add maping here */}

                  {  props.containersListResponse?.containerAutos?.filter(filterValue => filterValue.containers_id === list.id).map((contVehicle,i)=>{
           return(
          
<div className="col-md-3" key={i}>
                
                <div style={{ position: "relative" }}>
                  <div className="card">
                    <span style={{ color: "#084896", fontWeight: "600" }}>
                     {contVehicle.vin}
                    </span>
                    <span>
                      <span style={{ fontWeight: "550" }}>LOT: &nbsp;</span>
                      <span>{contVehicle.lot} </span>

                      <span style={{ fontWeight: "550" }}>By: &nbsp;</span>
                      <span>{contVehicle.userName}</span>
                    </span>
                  </div>
                  <div style={{ height: "6px" }}></div>
               {can(currentUserPermissions,'Delete Autos in Container') ? <div className="delete-icon-autos">
                  <Checkbox
          key={contVehicle.id}
          type="checkbox"
          name={contVehicle.id}
          id={contVehicle.id}
          handleClick={handleClick}
          isChecked={isCheck.find(li=>li===contVehicle.id)}
        />
                  </div>:''}
                </div>
        

      </div>

           )
           
            })}



       
  
      





                                  {/* add maping here */}



</div>






            </div>


           
          </div>

         




          <div className="outer-layer-icon-bar">
          <div className="icon-bar-container">

         { can(currentUserPermissions,'Add Autos to Container')?<a onClick={()=>{
            setOnId(list.id);
            props.showContainerAutoAdditionModal()
            }}>
              <i className="fa fa-plus" title="edit container information"></i>
            </a>:''}

            { can(currentUserPermissions,'Download Container Images Zip')?    <a onClick={()=>downloadZipImages(list.id)}>
            <i class="fas fa-file-archive" title="download container images"></i>
            </a>:''}

            { can(currentUserPermissions,'Edit Container')? <a onClick={()=>{
              props.getContainerDetails(list.id)
              setTimeout(
                ()=>{
                  props.showContainerEditModal()
                },550)
              }}>
              <i className="fa fa-edit" title="edit container information"></i>
            </a>:""}

            { can(currentUserPermissions,'Create Container Invoice')? <a onClick={()=>{
            
            setBillInfo({...billInfo,'containers_id':list.id})

            // props.showInvoiceRegModal()
            setTimeout(()=>{

           props.toggleContainerInvoiceModal()

            },550)
            
            }}>
          <i className="fas fa-file-invoice-dollar"></i>
                    </a>:''}


            { can(currentUserPermissions,'Delete Container')?   <a onClick={()=>{deleteContainer(list.id)}}>
              <i className="fa fa-trash" title="delete this container"></i>
            </a>:''}
          </div>
        </div>




</div>
    </div>
     





          )
        })}

        

        { can(currentUserPermissions,'Create Container')?<button
          className="floating-btn-container"
          onClick={() => props.showContainerRegModal()}
        >
          Create Container
        </button>:''}




        { props.containersListResponse?.data?.data.length===0 ? '':
<div>
      {props.containersListResponse?.data?.data ? (
        <div className="row">
          <div className="paginate col-md-6">
            <Pagination
              activePage={props.containersListResponse?.data?.current_page}
              itemsCountPerPage={props.containersListResponse?.data?.per_page}
              totalItemsCount={props.containersListResponse?.data?.total}
                // pageRangeDisplayed={5}
              onChange={searched==true? handleSearchPages:(pageNumber) => props.ContainersList(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
              lastPageText="Last"
              firstPageText="First"
            />
          </div>
        </div>
      ):''}

</div>
}





      </div>

      {/*
 =================================================================================================================
modal to add container information to the database
==================================================================================================================
*/}

      <Modal
        show={props.openContainerRegistrationModal}
        onHide={handleRegContainerClose}
        size="xl"
        scrollable={true}
      >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            Register Container
          </Modal.Title>
          <CloseButton
            variant={"white"}
            onClick={handleRegContainerClose}
          ></CloseButton>
        </Modal.Header>

        <form
          onSubmit={onstoreContainerData}
          encType="multipart/form-data"
          id="imageForm"
        >
          {/* {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )} */}

          <Modal.Body
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
            className="modal-body"
          >
            <div className="row">
            <div className="col-md-4 pb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select VINS
                </label>
         <AsyncPaginate
         debounceTimeout={300}
        additional={defaultAdditional}
        value={value}
        loadOptions={loadVins}
        onChange={onChange}
        isMulti
        closeMenuOnSelect={false}
        
      />
     {vinsFieldRequired? (
                  <div className="validation-error">Please select any VIN !</div>
                ) : (
                  ""
                )}

            </div>

              <div className="col-md-4 mb-4">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                  Point of Loading
                </label>
                <Select
                  options={landingPointOptions}
                  theme={theme}
                  placeholder="Loading Point"
                  name="point_of_loading"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={containerRegData?.point_of_loading || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />
                {props.autoEditErrors?.error.point_of_loading ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4 mb-2">
                <label htmlFor="port" className="picker-inputs-lebel">
                  Port
                </label>
                <Select
                  options={portOptions}
                  theme={theme}
                  placeholder="Port"
                  name="port"
                  isClearable={true}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  value={containerRegData?.port || ""}
                  // defaultValue={containerRegData?.port || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />

                {props.autoEditErrors?.error.port ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="container_number"
                    type="input"
                    name="container_number"
                    placeholder=" "
                    onChange={(e) => {
                      onInputChangeAdditional(e);
                    }}
                    value={containerRegData.container_number || ""}
                    required
                  />
                  <label htmlFor="container_number">Container Number</label>
                </Form.Floating>
                {props.containersCreationErrors?.error?.container_number ? (
                  <div className="validation-error">The Container Number has already been taken !</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-4 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="booking_number"
                    type="input"
                    name="booking_number"
                    placeholder=" "
                    onChange={(e) => {
                      onInputChangeAdditional(e);
                    }}
                    value={containerRegData.booking_number || ""}
                    required
                  />
                  <label htmlFor="booking_number">Booking Number</label>
                </Form.Floating>
              </div>
              <div className="col-md-4 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="lines"
                    type="input"
                    name="lines"
                    placeholder=" "
                    onChange={(e) => {
                      onInputChangeAdditional(e);
                    }}
                    value={containerRegData.lines || ""}
                    required
                  />
                  <label htmlFor="lines">Shipping Lines</label>
                </Form.Floating>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mb-2">
                <label htmlFor="loading_date" className="picker-inputs-lebel">
                  Date of loading
                </label>

                <DatePicker
                  selected={containerRegData.loading_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "loading_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Date of loading"
                  name="loading_date"
                  required={true}
                  autoComplete="off"
                />
              </div>

              <div className="col-md-3 mb-2">
                <label
                  htmlFor="expected_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Expected Arrival Date
                </label>

                <DatePicker
                  selected={containerRegData.expected_arrival_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "expected_arrival_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Expected Arrival Date"
                  name="expected_arrival_date"
                  required={true}
                  autoComplete="off"
                />
              </div>

              <div className="col-md-3 mb-2">
                <label
                  htmlFor="container_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Container Arrival Date
                </label>

                <DatePicker
                  selected={containerRegData.container_arrival_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "container_arrival_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Container Arrival Date"
                  name="container_arrival_date"
                  required={true}
                  autoComplete="off"
                />
              </div>
              <div className="col-md-3 mb-2">
                <label
                  htmlFor="container_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Unloading Date
                </label>

                <DatePicker
                  selected={containerRegData.container_unloading_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "container_unloading_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Unloading Date"
                  name="container_unloading_date"
                  required={true}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="row">

<div className="col-md-4">

{can(currentUserPermissions,'Add Container Images')?
<div style={{ marginTop: "30px" }}>
      
           
      <FileUploader
   
        handleChange={handleChangeImages}
        name="file"
        types={["jpeg","jpg"]}
        label={"Upload invoice here"}
        classes={""}
        multiple
        children={
          <div
            className={
              dragAndDropSelectedImages === false
                ? "dragDrop"
                : "dragDropSelected"
            }
          >
        
            <span>
              {dragAndDropTextImages}
              {dragAndDropSelectedImages === true ? (
                <span
                  style={{ fontSize: "11px", color: "darkgreen" }}
                >
                  {" "}
                  selected
                </span>
              ) : (
                ""
              )}
            </span>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "2px",
                color: "rgb(10, 80, 134)",
              }}
            >
              jpeg, jpg{" "}
              {dragAndDropSelectedImages === false ? (
                ""
              ) : (
                <i class="fas fa-check"></i>
              )}
            </div>
          </div>
        }
      />
      &nbsp;  &nbsp;

      { containerRegData.images.length>0? <a style={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{
        setcontainerRegData({...containerRegData,images:[]})
        setdragAndDropSelectedImages(false)
        setdragAndDropTextImages("Upload or Drag and Drop your images here")
   
        }    }>Reset Images</a>:'' }
    </div>:""}

</div>
<div className="col-md-8">

<div className="row ">
              
            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="dock_receipt" />
    <span className="file-icon">
                      <i class="fas fa-upload"></i>
                    </span> <span>{fileName.dock_receipt}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>


          
              <div className="col-md-6">
                <div className="fileuploadcontainer" >
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="bl_copy" />
    <span className="file-icon">
                      <i class="fas fa-upload"></i>
    </span> <span>{fileName.bl_copy}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className='row'>


            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf"  onChange={handleAttachment} name="telex_release"/>
    <span className="file-icon">
                      <i class="fas fa-upload"></i>
                    </span> <span>{fileName.telex_release}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>

              
            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="validations" />
    <span className="file-icon">
                      <i class="fas fa-upload"></i>
                    </span> <span>{fileName.validations}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>

            </div>









</div>


            </div>

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Create Container
            </button>
          </Modal.Footer>
        </form>
      </Modal>










  {/*
 =================================================================================================================
modal for editing container details
==================================================================================================================
*/}

<Modal
        show={props.openContainerEdit}
        onHide={handleEditContainerClose}
        size="fullscreen"
    

      >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            Edit Container Details
          </Modal.Title>
          <CloseButton
            variant={"white"}
            onClick={handleEditContainerClose}
          ></CloseButton>
        </Modal.Header>

        <form
          onSubmit={onUpdateContainerData}
          encType="multipart/form-data"
          id="imageForm"
        >
          {/* {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )} */}

          <Modal.Body
            style={{
            maxHeight: "calc(100vh - 140px)",
            overflowY: "auto",
            }}
            className="modal-body"
          >
            <div className="row">
            {/* <div className="col-md-4 pb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select VINS
                </label>
         <AsyncPaginate
         debounceTimeout={300}
        additional={defaultAdditional}
        value={value}
        loadOptions={loadPageOptions}
        onChange={onChange}
        isMulti
        closeMenuOnSelect={false}
      />


            </div> */}

              <div className="col-md-4 mb-4">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                  Point of Loading
                </label>
                <Select
                  options={landingPointOptions}
                  theme={theme}
                  placeholder="Loading Point"
                  name="point_of_loading"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  defaultValue={containerRegData?.point_of_loading || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />
                {props.autoEditErrors?.error.point_of_loading ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-md-4 mb-2">
                <label htmlFor="port" className="picker-inputs-lebel">
                  Port
                </label>
                <Select
                  options={portOptions}
                  theme={theme}
                  placeholder="Port"
                  name="port"
                  isClearable={true}
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  defaultValue={containerRegData?.port || ""}
                  onChange={(e, selector) =>
                    handleMoreInfoSelectors(e, selector)
                  }
                />

                {props.containersCreationErrors?.error.port ? (
                  <div className="validation-error">Required !</div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="container_number"
                    type="input"
                    name="container_number"
                    placeholder=" "
                    onChange={(e) => {
                      onInputChangeAdditional(e);
                    }}
                    value={containerRegData.container_number || ""}
                    required
                  />
                  <label htmlFor="container_number">Container Number</label>
                </Form.Floating>

                {props.containersUpdateErrors?.error?.container_number ? (
                  <div className="validation-error">The Container Number has already been taken !</div>
                ) : (
                  ""
                )}

              </div>
              <div className="col-md-4 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="booking_number"
                    type="input"
                    name="booking_number"
                    placeholder=" "
                    onChange={(e) => {
                      onInputChangeAdditional(e);
                    }}
                    value={containerRegData.booking_number || ""}
                    required
                  />
                  <label htmlFor="booking_number">Booking Number</label>
                  
                </Form.Floating>



              </div>
              <div className="col-md-4 mb-2">
                <Form.Floating>
                  <Form.Control
                    id="lines"
                    type="input"
                    name="lines"
                    placeholder=" "
                    onChange={(e) => {
                      onInputChangeAdditional(e);
                    }}
                    value={containerRegData.lines || ""}
                    required
                  />
                  <label htmlFor="lines">Shipping Lines</label>
                </Form.Floating>
              </div>
            </div>

            <div className="row">
              <div className="col-md-3 mb-2">
                <label htmlFor="loading_date" className="picker-inputs-lebel">
                  Date of loading
                </label>

                <DatePicker
                  selected={containerRegData.loading_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "loading_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Date of loading"
                  name="loading_date"
                  required={true}
                  autoComplete="off"
                />
              </div>

              <div className="col-md-3 mb-2">
                <label
                  htmlFor="expected_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Expected Arrival Date
                </label>

                <DatePicker
                  selected={containerRegData.expected_arrival_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "expected_arrival_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Expected Arrival Date"
                  name="expected_arrival_date"
                  required={true}
                  autoComplete="off"
                />
              </div>

              <div className="col-md-3 mb-2">
                <label
                  htmlFor="container_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Container Arrival Date
                </label>

                <DatePicker
                  selected={containerRegData.container_arrival_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "container_arrival_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Container Arrival Date"
                  name="container_arrival_date"
                  required={true}
                  autoComplete="off"
                />
                
              </div>



              <div className="col-md-3 mb-2">
                <label
                  htmlFor="container_arrival_date"
                  className="picker-inputs-lebel"
                >
                  Unloading Date
                </label>

                <DatePicker
                  selected={containerRegData.container_unloading_date}
                  onChange={(e) =>
                    handleDateInputChangeContainer(e, "container_unloading_date")
                  }
                  // minDate={moment().toDate()}
                  dateFormat="dd-MM-yyyy"
                  wrapperClassName="date-picker"
                  isClearable={true}
                  placeholderText="Unloading Date"
                  name="container_unloading_date"
                  required={true}
                  autoComplete="off"
                />
              </div>

              
            </div>

            <div className="row">
{can(currentUserPermissions,'Edit Container Images')?
<div className="col-md-4">


<div style={{ marginTop: "30px" }}>
      
           
      <FileUploader
   
        handleChange={handleChangeImages}
        name="file"
        types={["jpeg","jpg"]}
        label={"Upload invoice here"}
        classes={""}
        multiple
        children={
          <div
            className={
              dragAndDropSelectedImages === false
                ? "dragDrop"
                : "dragDropSelected"
            }
          >
        
            <span>
              {dragAndDropTextImages}
              {dragAndDropSelectedImages === true ? (
                <span
                  style={{ fontSize: "11px", color: "darkgreen" }}
                >
                  {" "}
                  selected
                </span>
              ) : (
                ""
              )}
            </span>
            <div
              style={{
                position: "absolute",
                fontSize: "10px",
                top: "2px",
                color: "rgb(10, 80, 134)",
              }}
            >
              jpeg, jpg{" "}
              {dragAndDropSelectedImages === false ? (
                ""
              ) : (
                <i class="fas fa-check"></i>
              )}
            </div>
          </div>
        }
      />
      &nbsp;  &nbsp;

      { containerRegData.images.length>0? <> <a style={{fontSize:'12px',cursor:'pointer'}} onClick={()=>{
        setcontainerRegData({...containerRegData,images:[]})
        setdragAndDropSelectedImages(false)
        setdragAndDropTextImages("Upload or Drag and Drop your images here")
   
        }    }>Reset Images</a>   &nbsp;       <a className="add-images-btn" onClick={()=>uploadImages()}>Add Images</a>     </>      :'' }
    </div>

</div>:""}
<div className="col-md-8">

<div className="row ">
              
            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="dock_receipt" />
    <span className="file-icon">
                      <i class="fas fa-upload"></i>
                    </span> <span>{fileName.dock_receipt}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>


          
              <div className="col-md-6">
                <div className="fileuploadcontainer" >
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="bl_copy" />
    <span className="file-icon">
                      <i class="fas fa-upload"></i>
    </span> <span>{fileName.bl_copy}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className='row'>


            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf"  onChange={handleAttachment} name="telex_release"/>
    <span className="file-icon">
                      <i class="fas fa-upload"></i>
                    </span> <span>{fileName.telex_release}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>

              
            <div className="col-md-6">
                <div className="fileuploadcontainer">
                <label className="custom-file-upload-container"  >
    <input type="file" accept="application/pdf" onChange={handleAttachment} name="validations" />
    <span className="file-icon">
                      <i class="fas fa-upload"></i>
                    </span> <span>{fileName.validations}</span>
</label>
                </div>

                {props.autoEditErrors?.error.invoice ? (
                  <div className="validation-error">
                    Please, add a valid pdf file, the pdf file you are uploading
                    has already in our system ! !
                  </div>
                ) : (
                  ""
                )}
              </div>

            </div>









</div>


{can(currentUserPermissions,'Edit Container Images')?
<ContainerImagesGrid id={containerRegData.onId}/>
:""}



            </div>

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Update Container
            </button>
          </Modal.Footer>
        </form>
      </Modal>









{/* 
========================================================
Modal for invoice information registration
======================================================== 
*/}

<Modal show={props.containerInvoiceModalToggle} onHide={()=>props.toggleContainerInvoiceModal()} size="md">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Create Invoice </Modal.Title>
          <CloseButton variant={"white"} onClick={()=>props.toggleContainerInvoiceModal()}></CloseButton>
        </Modal.Header>

        <form onSubmit={submitContainerInvoice} >
          {/* {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )} */}

          <Modal.Body
            className="modal-body"
          >
          <div className="row">
           

         
          <div className="col-md-12 mb-2">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                Bill To
                </label>
                <Select
                  options={billersOptions}
                  theme={theme}
                  placeholder="bill to"
                  name="bill_to"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body} 
                  styles={menuPortal}
            

                  onChange={(e,selector) => handleBillSelector(e,selector)}

                />
                    {props.autoEditErrors?.error.point_of_loading?<div className="validation-error">
                    Required !
                  </div>:''}
              </div>

          <div>
    {billEntries.map((entry, index) => (
      <div key={index} className="bill-entry">
        <div className="col-md-12 mb-2">
          <label htmlFor="invoice_catagory" className="picker-inputs-lebel">
            Invoice Type
          </label>
          <Select
            options={billCatagory}
            theme={theme}
            placeholder="Invoice Type"
            name={`invoice_catagory-${index}`}
            isClearable={true}
            zIndex={1}
            menuPortalTarget={document.body}
            styles={menuPortal}
            onChange={(e) => handleInputChange(index, 'invoice_catagory', e?.value || '')}
          />
        </div>
        <div className="col-md-12 mb-2">
      <label htmlFor={`vin-invoice-${index}`} className="picker-inputs-lebel">
        Select VIN
      </label>
      <AsyncPaginate
        debounceTimeout={300}
        additional={defaultAdditional}
        value={entry.vin}
        loadOptions={loadVins} // Function to fetch VIN options
        onChange={(selectedVin) => handleVinChange(index, selectedVin)}
        isClearable
      />
      {vinsFieldRequired && !entry.vin && (
        <div className="validation-error">Please select any VIN!</div>
      )}
    </div>

        <div className="col-md-12 mb-4">
          <Form.Floating>
            <Form.Control
              id={`amount-${index}`}
              type="number"
              name={`amount-${index}`}
              placeholder=" "
              onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
              required
              value={entry.amount || ''}
              autoComplete="off"
            />
            <label htmlFor={`amount-${index}`}>Payment</label>
          </Form.Floating>
        </div>

        <div className="col-md-12 mb-3">
          <label htmlFor={`notes-${index}`} className="form-label picker-inputs-lebel">
            Invoice Notes
          </label>
          <textarea
            className="form-control"
            id={`notes-${index}`}
            name={`notes-${index}`}
            value={entry.notes || ''}
            onChange={(e) => handleInputChange(index, 'notes', e.target.value)}
          ></textarea>
        </div>
      
        <i
        title="Remove the entry"
          className="fas fa-minus bill-remove-addition-button"
          onClick={() => removeBillEntry(index)}
        ></i>
<br/>
<hr/>
        
      </div>
    ))}

    <i className="fas fa-plus bill-entry-addition-button" title="Add new entry" onClick={addBillEntry}>
      
    </i>

    {/* <buttons
      type="button"
      className="btn btn-success"
      // onClick={submitBillEntries}
    >
      Submit
    </button> */}
  </div>

            {/* <div className="col-md-12 mb-4">
                <Form.Floating>
                  <Form.Control
                    id="bill_to"
                    type="input"
                    name="bill_to"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeBill(e)}}

                    value={billInfo.bill_to||''}
                    autoComplete="off"
                    
                  />
                  <label htmlFor="amount">Bill To</label>
                </Form.Floating>
              </div> */}



            
</div>



            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
         Create
            </button>
          </Modal.Footer>
        </form>
      </Modal>










{/* ========================================================
model for images
======================================================== */}

<Modal show={imagesModal} onHide={()=>{setImagesModal(false)}} size="fullscreen"  className="special_modal" centered >
        {/* <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">System Details</Modal.Title>
          <CloseButton variant={"white"} onClick={handleAdditionalClose}></CloseButton>
        </Modal.Header> */}

          {/* {Loading? (
            <Loader
              width={"40px"}
              height={"40px"}
              iconColor={"white"}
              backgroundColor={"#deb726"}
              left={"70%"}
              top={"110px"}
            />
          ) : (
            ""
          )} */}
         <div style={{position:'absolute',right:'5%', top:'1%', zIndex:'99999', backgroundColor:'white'}} className="circle2"> <CloseButton  style={{fontSize:'12px', color:'black'}} onClick={()=>{setImagesModal(false)}}></CloseButton></div>

          <Modal.Body
            className="modal-body"
          >
            <div className="row">
      {  can(currentUserPermissions,'Download Container Images Zip')?   <div style={{position:'fixed', top:'10px', left:'10px', color:'white', zIndex:'999'}}>
<button className="modal-images-download" onClick={()=>{props.downloadImagesContainerZip(onId);}}>
 Download Images
 </button>
        
       </div>:""}
              <div className="slider-container">

              <ContainerImagesCarousel/>


              </div>


            </div>

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

 
      </Modal>






{/* ========================================================
model for adding autos to the container
======================================================== */}

<Modal show={props.openContainerAutoAddition} onHide={handleAddAutoInContainerClose} size="md">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Add auto to the container </Modal.Title>
          <CloseButton variant={"white"} onClick={handleAddAutoInContainerClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={addAutosInContainer}>
         
          
          <Modal.Body
            className="modal-body"
          >
          <div className="row">
          <div className="col-md-12 pb-3">
            <label htmlFor="auction" className="picker-inputs-lebel">
                 Select auto VINS
                </label>
         <AsyncPaginate
         debounceTimeout={300}
        additional={defaultAdditional}
        value={autosInContainer}
        loadOptions={loadVins}
        onChange={setAutosInContainer}
        isMulti
        closeMenuOnSelect={false}
      />



                  <div className="validation-error">
                  {error}
                  </div>
               
               

            </div>
           
</div>



            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
           Add
            </button>
          </Modal.Footer>
        </form>
      </Modal>





 {/* ========================================================
Modal to view invoice information
======================================================== */}

<Modal show={props.invoiceInformationModalSwitch} onHide={props.invoiceInformationModalToggle} size="fullscreen">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            Invoice Information{" "}
          </Modal.Title>
          <CloseButton variant={"white"} onClick={props.invoiceInformationModalToggle}></CloseButton>
        </Modal.Header>

        <Modal.Body
          style={{
            maxHeight: "calc(120vh - 210px)",
            overflowY: "auto",
          }}
          className="modal-body"
        >

{props.noInvoicePresent? <NodataFound notFoundText={"No shipping invoice has been generated by the administration yet, please wait until they create one."} headerText="Oops!"  imageWidth="270px" imageHeight="270px" paddingRight="10px" headerColor="black" notFoundColor="#eb9e23"/> 

:
<BillView containerID={billInfo.containers_id} currentPage={props?.currentPage}/>
}

          {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
        </Modal.Body>

        <Modal.Footer className="modal-footer">
          <button className="btn-cancel" onClick={props.invoiceInformationModalToggle}>
            Close
          </button>

          {/* <button className="btn-submit" >Confirm</button> */}
        </Modal.Footer>
      </Modal>






    </>
  );
}








const mapStateToProps = (state) => {
  const { preDataLoadResponse } = state.preDataHandler;

  const { SelectorsFetch } = state.SelectorsHandler;
  const {containerInvoiceModalToggle,invoiceInformationModalSwitch,noInvoicePresent, openInvoiceReg,openInvoiceInfo} = state.ContainerInvoiceHandler;

  const { 
    loading,
    searchContainers,
    openContainerRegistrationModal,
    VinNumberFetchResponse,
    containersListResponse,
    containerImagesResponse,
    openContainerAutoAddition,
    currentPage,
    contAutosRegResponse,
    contAddAutosResponse,
    nullValues,
    containerSelectedAutosDeleteResponse,
    openContainerEdit,
    containerDetailsResponse,
    containerImagesUploadResponse,
    containerUpdateResponse,
    containerDeleteResponse,
    selectedDeletionContainerResponse,
    containersCreationErrors,
    containersUpdateErrors,
    containerImagesDeletionResponse,
    functionalLoader,
    
    
   
  } = state.ContainerHandler;

  return {
    loading:loading,
    searchContainers:searchContainers,
    currentPage:currentPage,
    openContainerRegistrationModal: openContainerRegistrationModal,
    openContainerAutoAddition:openContainerAutoAddition,

    VinNumberFetchResponse:VinNumberFetchResponse,
    containersListResponse:containersListResponse,
    containerImagesResponse:containerImagesResponse,
    contAutosRegResponse:contAutosRegResponse,
    contAddAutosResponse:contAddAutosResponse,
    nullValues:nullValues,
    containerSelectedAutosDeleteResponse:containerSelectedAutosDeleteResponse,
    openContainerEdit:openContainerEdit,
    containerDetailsResponse:containerDetailsResponse,
    containerImagesUploadResponse:containerImagesUploadResponse,
    containerUpdateResponse:containerUpdateResponse,
    containerDeleteResponse:containerDeleteResponse,
    selectedDeletionContainerResponse:selectedDeletionContainerResponse,
    containersCreationErrors:containersCreationErrors,
    SelectorsFetch:SelectorsFetch,
    containersUpdateErrors:containersUpdateErrors,
    preDataLoadResponse:preDataLoadResponse,
    containerImagesDeletionResponse:containerImagesDeletionResponse,
    functionalLoader:functionalLoader,
    containerInvoiceModalToggle:containerInvoiceModalToggle,
    invoiceInformationModalSwitch:invoiceInformationModalSwitch,
    noInvoicePresent:noInvoicePresent,



    
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSelectors: () => dispatch(getSelectors()),
    showContainerRegModal: () => dispatch(showContainerRegModal()),
    hideContainerRegModal: () => dispatch(hideContainerRegModal()),
    showContainerAutoAdditionModal: () => dispatch(showContainerAutoAdditionModal()),
    hideContainerAutoAdditionModal: () => dispatch(hideContainerAutoAdditionModal()),
    invoiceInformationModalToggle:()=>dispatch(invoiceInformationModalToggle()),
    toggleContainerInvoiceModal:()=>dispatch(toggleContainerInvoiceModal()),
    showContainerEditModal: () => dispatch(showContainerEditModal()),
    hideContainerEditModal: () => dispatch(hideContainerEditModal()),
    VinNumberFetchForContainers:(query)=>dispatch(VinNumberFetchForContainers(query)),
    ContainerRegistration:(registrationData,vinValues,currentPage)=>dispatch(ContainerRegistration(registrationData,vinValues,currentPage)),
    ContainersList:(pageNumber)=>dispatch(ContainersList(pageNumber)),
    getContainerImages:(id)=>dispatch(getContainerImages(id)),
    downloadImagesContainerZip:(id)=>dispatch(downloadImagesContainerZip(id)),
    ContainerDelete:(id)=>dispatch(ContainerDelete(id)),
    containerAddAutos:(id,vinValues,currentPage)=>dispatch(containerAddAutos(id,vinValues,currentPage)),
    selectedAutosInContainersDelete:(ids)=>dispatch(selectedAutosInContainersDelete(ids)),
    getContainerDetails:(id)=>dispatch(getContainerDetails(id)),
    ContainerUpdate:(registrationData,vinValues,currentPage)=>dispatch(ContainerUpdate(registrationData,vinValues,currentPage)),
    storeImagesContainer:(containerImages)=>dispatch(storeImagesContainer(containerImages)),
    selectedContainersDelete:(ids)=>dispatch(selectedContainersDelete(ids)),
    searchContainersList:(query,currentPage)=>dispatch(searchContainersList(query,currentPage)),
    inputHandler:({key,value})=>dispatch(inputHandler({key,value})),
    registerContainerInvoice:(data,vinDataInfo)=>dispatch(registerContainerInvoice(data,vinDataInfo)),
    getContainerInvoices:(id)=>dispatch(getContainerInvoices(id)),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContainerList);
