import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Logo from "../../assets/normLogo.png";

import { Outlet, NavLink,useNavigate } from "react-router-dom";
import "../../../css/tabs.css";
import Select from "react-select";
import { connect } from 'react-redux';
import _default from "react-bootstrap/Modal";

import { registerUser,showRegisterModal,hideRegisterModal,inputHandler,rolePicker } from "../../../redux/actions";

function RoleManagement(props) {
  const [backgroundTabs, setBackgroundTabs] = useState("nab-tabs");

  const listenScrollEvent = () => {
    if (window.scrollY > 99) {
      return setBackgroundTabs("nav-tabs-fix");
    } else if (window.scrollY < 80) {
      return setBackgroundTabs("nab-tabs");
    }
  };


  const handleClose = () => props.hideRegisterModal();
  const handleShow = () => props.showRegisterModal();

  const [userRegistrationData, setUserRegistrationData] = useState({name:'', username:'',email:'',role:[],password:'',confirm_password:''});
  
  
  const [userRegErrors, setUserRegErrors] = useState({password:null,role:false});

  const navigate = new useNavigate();


  useEffect(() =>{
    props.rolePicker();

  },[])

  useEffect(() => {
    // navigate('users-list')
    window.removeEventListener("scroll", listenScrollEvent);
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);


 
// ================================================================
// permissions functions
// ================================================================

const can=(permission)=>(props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;





  const onInputchange = (e) => {
    props.inputHandler({ key: e.target.name, value: e.target.value });

    // setUserRegistrationData({...userRegistrationData, [e.target.name]: e.target.value });
  };

  const handleChangeOption = selected => {
 
setUserRegistrationData({...userRegistrationData,'role':selected})

  };



const registerUser=(e)=>{
  e.preventDefault();
let name = props.name;
let username = props.username
let email = props.email
let contact = props.contact

//for role validation

 let role= userRegistrationData.role


//for password validation
let password= props.password
let confirm_password=props.confirm_password


if( userRegistrationData.role.length != 0){
if(password === confirm_password ){
  setUserRegErrors({password:null})
  setUserRegErrors({role:false})

  props.registerUser(name,username,email,role,contact,password,confirm_password);




}else{

  setUserRegErrors({password:'Please match the passwords'})

}
}else{
  setUserRegErrors({role:true})

}
  
}



  const theme = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 55,
      baseUnit: 4,
    },
  });

  const menuPortal = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };


  let roleOptions; 
if(props?.rolePickerData?.roles){
  roleOptions = props.rolePickerData?.roles.map(function (role) {
    return { value: role.id, label: role.name }
  })
}



// ============================================
// Validation purposes (granular level)
// ============================================

  let usernameError;
  let roleError;
  let passwordError;
  let emailError;

if(props.userRegistrationErrors?.error){
usernameError=props.userRegistrationErrors?.error?.username;
roleError=props.userRegistrationErrors?.error?.role;
passwordError=props.userRegistrationErrors.error?.password;
emailError=props.userRegistrationErrors.error?.email;

}



  return (
    <>

      <div>
     
        <div className="container py-2  main-container">
          {/* <div className="row mb-5">
            <div className="col-lg-8 text-white py-4 text-center mx-auto">
              <h1 className="display-4">Bootstrap 4 tabs</h1>
            </div>
          </div> */}

          <div className="p-5 mb-5">
            <div className={backgroundTabs}>
              <ul
                id="myTab2"
                className="nav nav-tabs nav-pills with-arrow lined flex-column flex-sm-row text-center"
              >
                {/* <li className="nav-item flex-sm-fill">
                  <NavLink
                    to=""
                    aria-selected="true"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
                    Users List  
                  </NavLink>
                </li>
                 */}
<li className="nav-item flex-sm-fill">
                  <NavLink
                    to="/home/RoleManagement" end
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
                    Manage Users
                  </NavLink>
                </li>
                
                <li className="nav-item flex-sm-fill">
                  <NavLink
                    to="roles"
                    aria-selected="false"
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link text-uppercase font-weight-bold rounded-0 tabActive"
                        : "nav-link text-uppercase font-weight-bold rounded-0"
                    }
                  >
                    Manage Role
                  </NavLink>
                </li>
              
              </ul>
            </div>
            <div className="tab-content">
         
              <button
                className="floating-btn"
                title="Register new user to system"
                onClick={handleShow}
              >
                + User
              </button>
           
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      {/* ========================================================
Modal of add user
======================================================== */}

      <Modal show={props.showModal} onHide={handleClose} size="md">
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">
            Register User{" "}
          </Modal.Title>
          <CloseButton variant={"white"} onClick={handleClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={registerUser} >
          <Modal.Body
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
            className="modal-body"
          >
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel  label="Name">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="name"
                    value={props.name}
                    required
                    onChange={(e)=>onInputchange(e)}
              
                  />
                </FloatingLabel>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel  label="Username">
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="username"
                    value={props.username}

                    required
                    onChange={(e)=>onInputchange(e)}
                    isInvalid={usernameError?true:false}

                  />
                </FloatingLabel>
                {usernameError?<div className="validation-error">The username already exists in our system !</div>:''}

              </div>
            </div>

            <div className="row">
              <div className="col-md-12 mb-3">
                <FloatingLabel
              
                  label="Email Address"
                >
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="email"
                    value={props.email}
                    email="true"
                    onChange={(e)=>onInputchange(e)}
                  />

                </FloatingLabel>
                {emailError?<div className="validation-error">Please, input a valid email address, e.g ghulam@gmail.com !</div>:''}

                
              </div>

              <div className="col-md-12 mb-3">
                <FloatingLabel
              
                  label="Contact No"
                >
                  <Form.Control
                    type="input"
                    placeholder=" "
                    name="contact"
                    value={props.contact||''}
                    onChange={(e)=>onInputchange(e)}
                  />

                </FloatingLabel>

                
              </div>

            </div>
            <div className="row">
              <div className="col-md-12 mb-3">
                <Select
                  options={roleOptions}
                  theme={theme}
                  placeholder="Select Role"
                  name="role"
                  menuPortalTarget={document.body}
                  styles={menuPortal}
                  // setValue={userRegistrationData.role}
                  onChange={(e)=>handleChangeOption(e)}
                  isClearable={true}
                  isMulti

                />
        
         {userRegErrors.role?<div className="validation-error">The role field is required !</div>:''}
         {roleError?<div className="validation-error">The role field is required !</div>:''}

              </div>

</div>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <FloatingLabel  label="Password">
                    <Form.Control
                      type="password"
                      placeholder=" "
                      name="password"
                      value={props.password}

                      required
                      onChange={(e)=>onInputchange(e)}
                      isInvalid={userRegErrors.password?true:false}
                      
                    />
                  </FloatingLabel>
                                      {userRegErrors.password?<div className="validation-error">Doesn't match !</div>:''}

                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mb-3">
                  <FloatingLabel

                    label="Confirm Password"
                  >
                    <Form.Control
                      type="password"
                      placeholder=" "
                      name="confirm_password"
                      value={props.confirm_password}
                      required
                      onChange={(e)=>onInputchange(e)}
                      isInvalid={userRegErrors.password?true:false}

                    />
                  </FloatingLabel>
                  {userRegErrors.password?<div className="validation-error">Doesn't match with password!</div>:''}

                </div>
              </div>
         

            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
              Register User
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}



const mapStateToProps = (state) => {
  const { userRegistrationErrors, showModal, userRegistrationResponse, name, username, email, contact, password, confirm_password} = state.authenticationHandler;

  const { loading, usersListResponse,rolePicker } = state.userManagementHandler;

  const { preDataLoadResponse } = state.preDataHandler;
 
 
  return {
    name:name,
    username:username,
    email:email,
    contact:contact,
    password:password,
    confirm_password:confirm_password,
    loading:loading,
    userRegistrationErrors:userRegistrationErrors,
    usersListResponse:usersListResponse,
    preDataLoadResponse:preDataLoadResponse,
    userRegistrationResponse:userRegistrationResponse,
    showModal: showModal,
    rolePickerData:rolePicker,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  registerUser: (name,username,email,role,contact,password,confirmPassword) =>
      dispatch(registerUser(name,username,email,role,contact,password,confirmPassword)),

  showRegisterModal: () =>
      dispatch(showRegisterModal()),

      hideRegisterModal: () =>
      dispatch(hideRegisterModal()),

      inputHandler:({key,value})=>dispatch(inputHandler({key,value})),
      rolePicker:()=>dispatch(rolePicker())
    };

 

};



export default  connect(mapStateToProps,mapDispatchToProps) (RoleManagement);
