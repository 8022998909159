import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";
import { saveAs } from 'file-saver';

var CryptoJS = require("crypto-js");




export const showInventoryRegModal=()=>{
  return {
    type:'openInventoryModal'
  }
  }




  export const hideInventoryRegModal=()=>{
    return {
      type:'hideInventoryModal'
    }
    }



export const showInventoryEditModal=()=>{
  return {
    type:'openInventoryEditModal'
  }
  }

  export const hideInventoryEditModal=()=>{
    return {
      type:'hideInventoryEditModal'
    }
    }


    export const showInventoryAdditionalModal=()=>{
      return {
        type:'openInventoryAdditionalModal'
      }
      }
    
      export const hideInventoryAdditionalModal=()=>{
        return {
          type:'hideInventoryAdditionalModal'
        }
        }
    

     


        export const showEditNoteInventoryModal=()=>{
          return {
            type:'openEditNoteInventoryModal'
          }
          }
        
        
        
        
          export const hideEditNoteInventoryModal=()=>{
            return {
              type:'hideEditNoteInventoryModal'
            }
            }



            export const showInventoryCommuncationNotesModal=()=>{
              return {
                type:'openInventoryCommuncationModal'
              }
              }
            
            
            
            
              export const hideInventoryCommuncationNotesModal=()=>{
                return {
                  type:'hideInventoryCommuncationModal'
                }
                }
    

// ---------------------------------------------------------------------------------------------------------
// this function will update point of landing directly of an inventory
// ---------------------------------------------------------------------------------------------------------

export const changePointOfLoadingInventory = (pointObj,currentPage) => {
//console.log(pointObj.onId)

  return (dispatch) => {
      dispatch(changePointOfLoadingStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
      let loadingData={
        point_of_loading:pointObj.point_of_loading.value||'',
        inventory_catagory:pointObj.inventory_catagory.value||'',

      
       }

  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/changePointOfLoadingStoreInventory/${pointObj.onId}`,loadingData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(changePointOfLoadingSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The point of landing has been changed for this inventory.  ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(changePointOfLoadingFailure(err.response.data.errors));

        });
    };
  };
  
  const changePointOfLoadingStarted = () => ({
    type: "changePointOfLoading_started",
  });
  
  
  const changePointOfLoadingSuccess = (data,currentPage) => ({
    type: "changePointOfLoading_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const changePointOfLoadingFailure = (error) => ({
    type: "changePointOfLoading_failure",
    payload: {
      error,
    }, 
  });




  



// ---------------------------------------------------------------------------------------------------------
// Download zip
// ---------------------------------------------------------------------------------------------------------



        export const downloadImagesZipInventory = (id) => {
          return (dispatch) => {
            dispatch(zipImagesStarted());
        
            const cipherToken = JSON.parse(localStorage.getItem("fkey"));
        
            var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
            var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        
            // //console.log(decryptedToken);
        
            let axiosConfig = {
              headers: {
                Accept: "application/zip",
                'Content-type': 'application/zip',
                Authorization: `Bearer ${decryptedToken}`,
            
              },
            };

            
              fetch(`${process.env.REACT_APP_API_ADDRESS}/zipFileDownloadInventory/${id}`, axiosConfig)
           
                    .then(res => res.blob())
                    .then(blob => saveAs(blob, 'Inventory Photos.zip'))


                 
              .catch((err) => {
                dispatch(zipImagesFailure(err.message));
              });
          };
        };
        
        const zipImagesStarted = () => ({
          type: "zipImages_started",
        });
        
        const zipImagesSuccess = (data) => ({
          type: "zipImages_success",
          payload: {
            ...data,
          },
        });
        
        
        const zipImagesFailure = (error) => ({
          type: "zipImages_failure",
          payload: {
            error,
          },
        });





// ---------------------------------------------------------------------------------------------------------
// Inventory List in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------

export const InventoryList = (pageNumber) => {
  return (dispatch) => {
    dispatch(InventoryListStarted());

    // const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    // var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    // var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",

      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/inventoryList?page=${pageNumber}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          // console.log(response.data);

          dispatch(InventoryListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(InventoryListFailure(err.message));
      });
  };
};

const InventoryListStarted = () => ({
  type: "Inventory_fetch_started",
});

const InventoryListSuccess = (data) => ({
  type: "Inventory_fetch_success",
  payload: {
    ...data,
  },
});


const InventoryListFailure = (error) => ({
  type: "Inventory_fetch_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// this function will update or add data
// ---------------------------------------------------------------------------------------------------------

export const inventoryAdditionalRegistration = (registrationData,currentPage,selector) => {


  const inventoryRegAdditionalData = new FormData();

  for (let i = 0; i < registrationData?.images.length; i++) {
    inventoryRegAdditionalData.append("images[]", registrationData?.images[i]);
  }

  let pickUp= registrationData.pickUp?moment(registrationData?.pickUp).format('YYYY-MM-DD'):'';
  let toWarehouse=registrationData.toWarehouse? moment(registrationData?.toWarehouse).format('YYYY-MM-DD'):'';
  let arrival_date=registrationData.arrival_date? moment(registrationData?.arrival_date).format('YYYY-MM-DD'):'';
  let unloading_date= registrationData.unloading_date? moment(registrationData?.unloading_date).format('YYYY-MM-DD'):'';
  let title_received= registrationData.title_received? moment(registrationData?.title_received).format('YYYY-MM-DD'):'';
  let purchase_date= registrationData.purchase_date? moment(registrationData?.purchase_date).format('YYYY-MM-DD'):'';
  let payment_to_auction= registrationData.payment_to_auction? moment(registrationData?.payment_to_auction).format('YYYY-MM-DD'):'';

  inventoryRegAdditionalData.append("customerId", selector?.value||'');
  inventoryRegAdditionalData.append("for_whom", selector?.label||'');


  inventoryRegAdditionalData.append("id", registrationData.onId);
  inventoryRegAdditionalData.append("containerNoBooking", registrationData?.containerNoBooking||'');
  inventoryRegAdditionalData.append("pickUp", pickUp||'');
  inventoryRegAdditionalData.append("toWarehouse", toWarehouse||'');
  inventoryRegAdditionalData.append("arrival_date", arrival_date||'');
  inventoryRegAdditionalData.append("unloading_date", unloading_date||'');
  inventoryRegAdditionalData.append("title_received", title_received||'');
  inventoryRegAdditionalData.append("title_status", registrationData.title_status?.value||'');
  inventoryRegAdditionalData.append("inventory_catagory", registrationData.inventory_catagory?.value||'');
  inventoryRegAdditionalData.append("keys", registrationData?.keys||'');
  inventoryRegAdditionalData.append("terminal_notes", registrationData?.terminal_notes||'');

// ======================
// non admin info update
// ======================
  inventoryRegAdditionalData.append("purchase_date", purchase_date||'');
  inventoryRegAdditionalData.append("payment_to_auction", payment_to_auction||'');
  inventoryRegAdditionalData.append("vin", registrationData?.vin||'');
  inventoryRegAdditionalData.append("lot", registrationData?.lot||'');

  inventoryRegAdditionalData.append("model", registrationData?.model||'');
  inventoryRegAdditionalData.append("make", registrationData?.make||'');
  inventoryRegAdditionalData.append("year", registrationData?.year||'');
  inventoryRegAdditionalData.append("color", registrationData?.color||'');
  inventoryRegAdditionalData.append("inventoryType", registrationData.inventoryType?.value||'');
  inventoryRegAdditionalData.append("auction", registrationData.auction?.value||'');

  inventoryRegAdditionalData.append("buyer_no", registrationData.buyer_no?.value||'');
  inventoryRegAdditionalData.append("city", registrationData.city?.value||'');
  inventoryRegAdditionalData.append("customer_notes", registrationData.customer_notes||'');
  inventoryRegAdditionalData.append("port", registrationData.port?.value||'');
  inventoryRegAdditionalData.append("point_of_loading", registrationData.point_of_loading?.value||'');
  inventoryRegAdditionalData.append("dismantle", registrationData.dismantle||'');
  inventoryRegAdditionalData.append("self_delivered", registrationData.self_delivered||'');
  inventoryRegAdditionalData.append("invoice", registrationData.invoice||'');
  inventoryRegAdditionalData.append("inventory_price", registrationData.inventory_price||'');



  return (dispatch) => {
      dispatch(inventoryAdditionalRegStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

        onUploadProgress: function(progressEvent) {
         const {loaded,total}=progressEvent;
          let percentage = Math.floor( loaded / total* 100 );
          if(percentage< 100){
         dispatch(onUploadProgress(percentage))
          }
          //console.log(`${loaded}kb of ${total}kb  ${percentage} %`);
        }
      };
     
  
      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/inventoryAdditionalDataStore`,inventoryRegAdditionalData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(inventoryAdditionalRegSuccess(response.data,currentPage));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The details have been successfully created ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } else if(response.data.error_message){


            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              text: 'The invoice that you are uploading is already in our system, please upload another invoice',
              showConfirmButton: false,
              timer: 3000,
              toast: true,
  
            })


          }
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(inventoryAdditionalRegFailure(err.response.data.errors));

        });
    };
  };
  
  const inventoryAdditionalRegStarted = () => ({
    type: "inventoryAdditionalReg_started",
  });
  
  
  const inventoryAdditionalRegSuccess = (data,currentPage) => ({
    type: "inventoryAdditionalReg_success",
    payload: {
      ...data,'currentPage':currentPage,
    },
  });
  
  const inventoryAdditionalRegFailure = (error) => ({
    type: "inventoryAdditionalReg_failure",
    payload: {
      error,
    }, 
  });


  const onUploadProgress = (progressPercent) => ({
    type: "upload_progress",
    payload: {
      progressPercent,
    },
  });

  export const resetProgressBarInventory=()=>{
    return {
      type:'resetProgressBar'
    }
    }
  





// ---------------------------------------------------------------------------------------------------------
// the get data to edit it on additional modal
// ---------------------------------------------------------------------------------------------------------

export const getInventoryAdditionalDetails = (id) => {



  return (dispatch) => {
      dispatch(getInventoryAdditionalRegStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  
      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/getAdditionalRegDataInventory/`+id,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getInventoryAdditionalRegSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getInventoryAdditionalRegFailure(err.response.data.errors));

        });
    };
  };
  
  const getInventoryAdditionalRegStarted = () => ({
    type: "getInventoryAdditionalReg_started",
  });
  
  
  const getInventoryAdditionalRegSuccess = (data) => ({
    type: "getInventoryAdditionalReg_success",
    payload: {
      ...data,
    },
  });
  
  const getInventoryAdditionalRegFailure = (error) => ({
    type: "getInventoryAdditionalReg_failure",
    payload: {
      error,
    }, 
  });









// ---------------------------------------------------------------------------------------------------------
// All data would be store in the Inventory table of database
// ---------------------------------------------------------------------------------------------------------

export const storeInventoryData = (inventoryData,CustomerId) => {
    return (dispatch) => {
      dispatch(storeDataStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          //console.log(percentCompleted);
        }



      };
let purchase_date_formatted= moment(inventoryData.purchase_date).format('YYYY-MM-DD');
let auction_payment= moment(inventoryData.payment_to_auction).format('YYYY-MM-DD');

const inventoryRegistrationData = new FormData();
inventoryRegistrationData.append("vin", inventoryData.vin);
inventoryRegistrationData.append("lot", inventoryData.lot);
inventoryRegistrationData.append("autoType", inventoryData.autoType?.value||'');
inventoryRegistrationData.append("customerId", CustomerId?.value||'');
inventoryRegistrationData.append("for_whom", CustomerId?.label||'');

inventoryRegistrationData.append("model", inventoryData.model||'');
inventoryRegistrationData.append("make", inventoryData.make||'');
inventoryRegistrationData.append("year", inventoryData.year||'');
inventoryRegistrationData.append("color", inventoryData.color||'');
inventoryRegistrationData.append("purchase_date", purchase_date_formatted||'');
inventoryRegistrationData.append("auction", inventoryData.auction?.value||'');
inventoryRegistrationData.append("buyer_no", inventoryData.buyer_no?.value||'');
inventoryRegistrationData.append("city", inventoryData.city?.value||'');
inventoryRegistrationData.append("customer_notes", inventoryData.customer_notes||'');
inventoryRegistrationData.append("payment_to_auction", auction_payment||'');
inventoryRegistrationData.append("port", inventoryData.port?.value||'');
inventoryRegistrationData.append("point_of_loading", inventoryData.point_of_loading?.value||'');
inventoryRegistrationData.append("dismantle", inventoryData.dismantle||'');
inventoryRegistrationData.append("self_delivered", inventoryData.self_delivered||'');
inventoryRegistrationData.append("invoice", inventoryData.invoice||'');
inventoryRegistrationData.append("inventory_price", inventoryData.inventory_price||'');





      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/inventoryDataStore`, inventoryRegistrationData, axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
  
            dispatch(storeDataSuccess(response.data));

            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The Inventory has succesfully registered',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } else if(response.data.error_message){


            Swal.fire({
              position: 'top-end',
              icon: 'warning',
              text: 'The invoice that you are uploading is already in our system, please upload another invoice',
              showConfirmButton: false,
              timer: 3000,
              toast: true,
  
            })


          }
        })
        .catch((err) => {
          //console.log(err.response.data.errors)
          dispatch(storeDataFailure(err.response.data.errors));
        });
    };
  };
  
  const storeDataStarted = () => ({
    type: "storeInventoryData_started",
  });
  
  const storeDataSuccess = (data) => ({
    type: "storeInventoryData_success",
    payload: {
      ...data,
    },
  });
  
  const storeDataFailure = (error) => ({
    type: "storeInventoryData_failure",
    payload: {
      error,
    },
  });
  




// ---------------------------------------------------------------------------------------------------------
// getImages from inventory_images table for each inventory 
// ---------------------------------------------------------------------------------------------------------

export const getImagesInventory = (id) => {
  return (dispatch) => {
    dispatch(imagesListStarted());

  
    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/imagesListInventory/`+id, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(imagesListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(imagesListFailure(err.message));
      });
  };
};

const imagesListStarted = () => ({
  type: "images_fetch_started",
});

const imagesListSuccess = (data) => ({
  type: "images_fetch_success_Inventory",
  payload: {
    ...data,
  },
});


const imagesListFailure = (error) => ({
  type: "images_fetch_failure",
  payload: {
    error,
  },
});









// ---------------------------------------------------------------------------------------------------------
// Admin can delete images so this funciton will help to delete images for each inventory multiple images
// ---------------------------------------------------------------------------------------------------------

export const imagesDeleteInventory = (ids,inventoryId) => {


  return (dispatch) => {
    dispatch(imagesDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteImagesInventory/`+ids+'/'+inventoryId,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // console.log(response.data)

          dispatch(imagesDeletionSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The images has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(imagesDeletionFailure(err.message));
      });
  };
};

const imagesDeletionStarted = () => ({
  type: "imagesDeletion_started",
});

const imagesDeletionSuccess = (data) => ({
  type: "imagesDeletion_success",
  payload: {
    ...data,
  },
});

const imagesDeletionFailure = (error) => ({
  type: "imagesDeletion_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// filtering warehouses
// ---------------------------------------------------------------------------------------------------------

export const filterListForWarhousesInventory = (warehouse,placement,pageNumber) => {
  // console.log(pageNumber)

    return (dispatch) => {
        dispatch(filterListsForWarehosuesStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        // //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
        // let loadingData={
        //   warehouse:warehouse||'',
        //   inventory_placement:placement||'',
  
        
        //  }

        //  let loadingData = {
        //   params: {
        //     warehouse:warehouse||'',
        //     inventory_placement:placement||'',

        //   }
        // }

        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/filterWarehousesInventory/${warehouse}/${placement}?page=${pageNumber}`,axiosConfig)
          .then((response) => {
            if (response.data.success_message) {
              // console.log(response.data);
              dispatch(filterListsForWarehosuesSuccess(response.data));
          
            } 
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(filterListsForWarehosuesFailure(err.response.data.errors));
  
          });
      };
    };
    
    const filterListsForWarehosuesStarted = () => ({
      type: "filterOnWarehouses_started",
    });
    
    
    const filterListsForWarehosuesSuccess = (data) => ({
      type: "filterOnWarehouses_success",
      payload: {
        ...data,
      },
    });
    
    const filterListsForWarehosuesFailure = (error) => ({
      type: "filterOnWarehouses_failure",
      payload: {
        error,
      }, 
    });
  
  
  





// ---------------------------------------------------------------------------------------------------------
// Inventory multiple delete
// ---------------------------------------------------------------------------------------------------------

export const selectedInventoryDelete = (ids) => {


  return (dispatch) => {
    dispatch(selectedInventoryStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   let data={
    ids:ids
   }


    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/deleteSelectedInventory/`,data,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(selectedInventorySuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The selected Inventory has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(selectedInventoryFailure(err.message));
      });
  };
};

const selectedInventoryStarted = () => ({
  type: "selectedInventory_started",
});

const selectedInventorySuccess = (data) => ({
  type: "selectedInventory_success",
  payload: {
    ...data,
  },
});

const selectedInventoryFailure = (error) => ({
  type: "selectedInventory_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// Inventory Delete
// ---------------------------------------------------------------------------------------------------------

export const inventoryDelete = (id) => {


  return (dispatch) => {
    dispatch(inventoryDeleteStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteInventory/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(inventoryDeleteSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The inventory has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(inventoryDeleteFailure(err.message));
      });
  };
};

const inventoryDeleteStarted = () => ({
  type: "inventoryDelete_started",
});

const inventoryDeleteSuccess = (data) => ({
  type: "inventoryDelete_success",
  payload: {
    ...data,
  },
});

const inventoryDeleteFailure = (error) => ({
  type: "inventoryDelete_failure",
  payload: {
    error,
  },
});



  // **********************************************************************************************************
  // **********************************************************************************************************

// Communication on notes, actions starts from here ....................

  // **********************************************************************************************************
  // **********************************************************************************************************




// ---------------------------------------------------------------------------------------------------------
// get communication of the Inventory, or get all notes
// ---------------------------------------------------------------------------------------------------------

export const getInventoryCommunication = (InventoryId) => {


  return (dispatch) => {
      dispatch(getCommunicationStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
      let data={
        InventoryId:InventoryId,
      }
    

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/fetchInventoryCommunication/`,data,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getCommunicationSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getCommunicationFailure(err.response.data.errors));

        });
    };
  };
  
  const getCommunicationStarted = () => ({
    type: "getCommunicationInventory_started",
  });
  
  
  const getCommunicationSuccess = (data) => ({
    type: "getCommunicationInventory_success",
    payload: {
      ...data,
    },
  });
  
  const getCommunicationFailure = (error) => ({
    type: "getCommunicationInventory_failure",
    payload: {
      error,
    }, 
  });










// ---------------------------------------------------------------------------------------------------------
// register comment for adding the notes
// ---------------------------------------------------------------------------------------------------------

export const InventoryCommunication = (InventoryId,bodyText,fileAttachment) => {


  return (dispatch) => {
      dispatch(inventoryCommunicationStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };



      const noteData = new FormData();
      noteData.append("InventoryId", InventoryId);
      noteData.append("body", bodyText);
      noteData.append("fileAttachment", fileAttachment);

      
     
  // let data={
  //   InventoryId:InventoryId,
  //   body:bodyText,
  //   fileAttachment:fileAttachment,
  // }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/commentInventoryRegister/`,noteData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(inventoryCommunicationSuccess(response.data));
          
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(inventoryCommunicationFailure(err.response.data.errors));

        });
    };
  };
  
  const inventoryCommunicationStarted = () => ({
    type: "inventoryCommunication_started",
  });
  
  
  const inventoryCommunicationSuccess = (data) => ({
    type: "inventoryCommunication_success",
    payload: {
      ...data,
    },
  });
  
  const inventoryCommunicationFailure = (error) => ({
    type: "inventoryCommunication_failure",
    payload: {
      error,
    }, 
  });






// ---------------------------------------------------------------------------------------------------------
// get the data of the edit 
// ---------------------------------------------------------------------------------------------------------

export const getDataEditInventoryNote = (id) => {


  return (dispatch) => {
      dispatch(getEditInventoryNoteStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     

      axios
        .get(`${process.env.REACT_APP_API_ADDRESS}/getEditInventoryNoteDetails/`+id,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(getEditInventoryNoteSuccess(response.data));
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(getEditInventoryNoteFailure(err.response.data.errors));

        });
    };
  };
  
  const getEditInventoryNoteStarted = () => ({
    type: "getEditInventoryNoteDetails_started",
  });
  
  
  const getEditInventoryNoteSuccess = (data) => ({
    type: "getEditInventoryNoteDetails_success",
    payload: {
      ...data,
    },
  });
  
  const getEditInventoryNoteFailure = (error) => ({
    type: "getEditInventoryNoteDetails_failure",
    payload: {
      error,
    }, 
  });











// ---------------------------------------------------------------------------------------------------------
// delete selected Inventory notes
// ---------------------------------------------------------------------------------------------------------

export const editSelectedInventoryNotes = (selectedNoteId,body,fileAttachment) => {


  return (dispatch) => {
      dispatch(editSelectedInventoryNotesStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     

      const noteData = new FormData();
      noteData.append("selectedNoteId", selectedNoteId);
      noteData.append("body", body);
      noteData.append("fileAttachment", fileAttachment);


  // let data={
  //   selectedNoteId:selectedNoteId,
  //   body:body,
  // }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/updateInventoryNote/`,noteData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(editSelectedInventoryNotesSuccess(response.data));
        
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The note has been successfully edited',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })


          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(editSelectedInventoryNotesFailure(err.response.data.errors));

        });
    };
  };
  
  const editSelectedInventoryNotesStarted = () => ({
    type: "editSelectedInventoryNotes_started",
  });
  
  
  const editSelectedInventoryNotesSuccess = (data) => ({
    type: "editSelectedInventoryNotes_success",
    payload: {
      ...data,
    },
  });
  
  const editSelectedInventoryNotesFailure = (error) => ({
    type: "editSelectedInventoryNotes_failure",
    payload: {
      error,
    }, 
  });











// ---------------------------------------------------------------------------------------------------------
// delete selected Inventory notes
// ---------------------------------------------------------------------------------------------------------

export const deleteSelectedInventoryNotes = (selectedNotes,InventoryId) => {


  return (dispatch) => {
      dispatch(deleteSelectedInventoryNotesStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
     
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },

      };
     
  let data={
    selectedNotes:selectedNotes,
    InventoryId:InventoryId,
  }

      axios
        .post(`${process.env.REACT_APP_API_ADDRESS}/deleteInventoryNotes/`,data,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(deleteSelectedInventoryNotesSuccess(response.data));
        
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The selected notes are deleted successfully.',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })


          } 
        })
        .catch((err) => {
          //console.log(err.response.data);
          dispatch(deleteSelectedInventoryNotesFailure(err.response.data.errors));

        });
    };
  };
  
  const deleteSelectedInventoryNotesStarted = () => ({
    type: "deleteSelectedInventoryNotes_started",
  });
  
  
  const deleteSelectedInventoryNotesSuccess = (data) => ({
    type: "deleteSelectedInventoryNotes_success",
    payload: {
      ...data,
    },
  });
  
  const deleteSelectedInventoryNotesFailure = (error) => ({
    type: "deleteSelectedInventoryNotes_failure",
    payload: {
      error,
    }, 
  });






  








// ---------------------------------------------------------------------------------------------------------
// users selector
// ---------------------------------------------------------------------------------------------------------

export const usersSelectorListInventory = (query,page) => {
  return (dispatch) => {
    dispatch(usersSelectorListStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
// //console.log(query)
if(query){
  let data={
    q:query
  }
    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/userSelectorListQueryInventory`, data, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(usersSelectorListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(usersSelectorListFailure(err.message));
      });
    }else{
      axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/usersSelectorListPaginateInventory?page=${page}`, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(usersSelectorListSuccess(response.data));

      
        } 
      })
      .catch((err) => {
        dispatch(usersSelectorListFailure(err.message));
      });
    }
  };
};

const usersSelectorListStarted = () => ({
  type: "usersSelectorList_started",
});

const usersSelectorListSuccess = (data) => ({
  type: "usersSelectorList_success",
  payload: {
    ...data,
  },
});


const usersSelectorListFailure = (error) => ({
  type: "usersSelectorList_failure",
  payload: {
    error,
  },
});







// ---------------------------------------------------------------------------------------------------------
// to store only images of the container
// ---------------------------------------------------------------------------------------------------------

export const storeImagesInventory = (InventoryData) => {
  return (dispatch) => {
    dispatch(storeImagesStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },

    };

const InventoryImagesData = new FormData();

InventoryImagesData.append("id", InventoryData?.onId);

  for (let i = 0; i < InventoryData?.images.length; i++) {
    InventoryImagesData.append("images[]", InventoryData?.images[i]);
  }




    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/storeInventoryImages`, InventoryImagesData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);

          dispatch(storeImagesSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The images has been successfully uploaded',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors)
        dispatch(storeImagesFailure(err.response.data.errors));
      });
  };
};

const storeImagesStarted = () => ({
  type: "storeInventoryImages_started",
});

const storeImagesSuccess = (data) => ({
  type: "storeInventoryImages_success",
  payload: {
    ...data,
  },
});

const storeImagesFailure = (error) => ({
  type: "storeInventoryImages_failure",
  payload: {
    error,
  },
});
