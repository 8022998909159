const InitialStates = {
  loading:false,
    openInvoiceInfo:false,
    openInvoiceReg:false,
    noInvoicePresent:false,
    containerDeleteInfoBill:null,
    containerPrintResponse:null,
    openInvoiceEditInfo:false,
    openInvoiceEditBiller:false,
    containerInvoiceModalToggle:false,
    invoiceInformationModalSwitch:false,
  };
  
  export default (state = InitialStates, action) => {
    switch (action.type) {

      case "toggleContainerInvoiceModal":
        return  {...state, 'containerInvoiceModalToggle': !state.containerInvoiceModalToggle};

      case "invoiceInformationModal":
        return  {...state, 'invoiceInformationModalSwitch': !state.invoiceInformationModalSwitch};

    
        case 'openInvoiceInformationModal':
            return { ...state, 'openInvoiceInfo':true };
    
            case 'hideInvoiceInformationModal':
              return { ...state, 'openInvoiceInfo':false };


          case 'openinvoiceRegistrationModal':
            return { ...state, 'openInvoiceReg':true };
    
            case 'hideinvoiceRegistrationModal':
              return { ...state, 'openInvoiceReg':false };

              case 'openInvoiceInfoEditModal':
                return { ...state, 'openInvoiceEditInfo':true };
        
                case 'hideInvoiceInfoEditModal':
                  return { ...state, 'openInvoiceEditInfo':false };

                  case 'openInvoiceBillerEditModal':
                    return { ...state, 'openInvoiceEditBiller':true };
            
                    case 'hideInvoiceBillerEditModal':
                      return { ...state, 'openInvoiceEditBiller':false };
      
  
      case "getContainerInvoices_success":
         if(action.payload.error_message){
          return {...state,'containerInvoiceResponse': action.payload, 'noInvoicePresent': true, 'loading': false };

         }else{
          return {...state, 'containerInvoiceResponse': action.payload, 'loading': false,'noInvoicePresent': false };

         }

        case "ContainerInvoiceDeletion_success":
          // console.log(action.payload);
          return {...state, 'containerDeleteInfoBill': action.payload, 'loading': false };

          case "registerContainerInvoice_success":
            console.log(action.payload);

            return {...state, 'registrationSuccessContainerInvoice': action.payload, 'loading': false,'openInvoiceReg':false , 'containerInvoiceModalToggle':false};
  
            case "invoicePrint_success":
              return {...state, 'containerPrintResponse': action.payload, 'loading': false };

case "ContainerInvoiceEditInfoDetails_success":
  return {...state, 'invoiceEditInfo': action.payload, 'loading': false };

case "ContainerInvoiceInfoUpdate_success":
  return {...state, 'invoiceInfoUpdationResponse': action.payload, 'loading': false ,'openInvoiceEditInfo':false};

case "ContainerInvoiceEditBiller_success":
  return {...state, 'invoiceBillerEditResponse': action.payload, 'loading': false ,'openInvoiceEditInfo':false};

  case "ContainerInvoiceBillerUpdate_success":
    return {...state, 'invoiceBillerUpdateResponse': action.payload, 'loading': false ,'openInvoiceEditBiller':false};
case "ContainerInvoiceOriginDeletion_success":
  return {...state, 'invoiceOriginDeletionResponse': action.payload, 'loading': false ,'invoiceInformationModalSwitch':false};

      default:
        return state;
    }
  };
  