import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../../../css/carousel.css';
import { connect } from 'react-redux';
import {getContainerImages} from "../../../redux/actions";
import Loader from "../../elements/Loader";
import ImageCustomCarousel from './../../elements/ImageCustomCarousel'; // Import your custom carousel component


function ContainerImagesCarousel(props) {
  return (
    <>
      <div className="carousel-outer">
        {props.containerImagesResponse?.images ? (
          <ImageCustomCarousel
          zoomEffect={true}
          customThumnailColor={'#fcac32'}
            images={props.containerImagesResponse?.images.map((img) => `${process.env.REACT_APP_CONTAINER_IMAGES}/${img.image_name}`)} // Pass the image URLs as the `images` prop
          />
        ) : (
          <Loader
            width={"40px"}
            height={"40px"}
            iconColor={"white"}
            backgroundColor={"#deb726"}
            left={"50%"}
            top={"300px"}
          />
        )}
      </div>
    </>
  );
}


const mapStateToProps = (state) => {

    const {loading,containerImagesResponse} =  state.ContainerHandler;
  
  
    return {
   loading:loading,
   containerImagesResponse:containerImagesResponse,
      
    
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
  
    
        getContainerImages:(id)=>dispatch(getContainerImages(id))
  
    };
  };
  
  





export default connect(mapStateToProps,mapDispatchToProps) (ContainerImagesCarousel)



