const InitialStates = {
    preDataLoadResponse:null,
    functionalLoader:false,
     loading: false,

     InventoryRegistrationSuccess:null,
     InventoryAdditionalRegistrationSuccess:null,
     InventoryRegistrationErrors:null,
     openInventoryModal:false,
     openInventoryEditModal:false,
     openInventoryAdditionalModal:false,
     getAdditionalRegDataSuccess:null,
     name:'',
     currentPage:1,
   cleanValues:false,
   uploadPercentage:0,
   catagoryValue:false,
   openInvoiceInfo:false,
   openInvoiceReg:false,
   openInventoryCommuncation:false,
   openEditNoteInventory:false,
   cleanAdditionalValues:false,
   nullValues:false,
   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
       
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value}

       case 'openInventoryModal':
        return { ...state, 'openInventoryModal':true };

        case 'hideInventoryModal':
          return { ...state, 'openInventoryModal':false };

          case 'openInventoryEditModal':
            return { ...state, 'openInventoryEditModal':true };
    
            case 'hideInventoryEditModal':
              return { ...state, 'openInventoryEditModal':false };

              case 'openInventoryAdditionalModal':
                return { ...state, 'openInventoryAdditionalModal':true };
        
                case 'hideInventoryAdditionalModal':
                  return { ...state, 'openInventoryAdditionalModal':false };


                  case 'openInvoiceInformationModal':
                    return { ...state, 'openInvoiceInfo':true };
            
                    case 'hideInvoiceInformationModal':
                      return { ...state, 'openInvoiceInfo':false };


                  case 'openinvoiceRegistrationModal':
                    return { ...state, 'openInvoiceReg':true };
            
                    case 'hideinvoiceRegistrationModal':
                      return { ...state, 'openInvoiceReg':false };


                      case 'openInventoryCommuncationModal':
                        return { ...state, 'openInventoryCommuncation':true };
                
                        case 'hideInventoryCommuncationModal':
                          return { ...state, 'openInventoryCommuncation':false };



                          case 'openEditNoteInventoryModal':
                            return { ...state, 'openEditNoteInventory':true };
                    
                            case 'hideEditNoteInventoryModal':
                              return { ...state, 'openEditNoteInventory':false };

//=============================               
//for loading purposes
//=============================
          case "Inventory_fetch_started":
        
            return { ...state, 'loading':true };

            case "filterOnWarehouses_started":
        
              return { ...state, 'loading':true };

              
              case "searchInventorys_started":
        
                return { ...state, 'loading':true };

                case "changePointOfLoading_started":
        
                  return { ...state, 'loading':true };


                case "InventoryAdditionalReg_started":
        
                  return { ...state, 'functionalLoader':true };



                  case "storeInventoryData_started":
        
                    return { ...state, 'functionalLoader':true };


                    case "imagesDeletion_started":
        
                      return { ...state, 'functionalLoader':true };


                      case "selectedInventorys_started":
        
                        return { ...state, 'loading':true };

                        case "getCommunicationInventory_started":
        
                          return { ...state, 'functionalLoader':true };

                          case "InventoryDelete_started":
        
                            return { ...state, 'loading':true };

                            case "InventoryCommunication_started":
        
                              return { ...state, 'functionalLoader':true };


                              case "editSelectedInventoryNotes_started":
        
                              return { ...state, 'functionalLoader':true };


                              case "deleteSelectedInventoryNotes_started":
        
                                return { ...state, 'functionalLoader':true };


                                case "storeInventorysImages_started":
        
                                  return { ...state, 'functionalLoader':true };
  

                                  case "storeInventorysImages_started":
        
                                    return { ...state, 'functionalLoader':true };

                  // case "zipImages_started":
        
                  //   return { ...state, 'functionalLoader':true };
                
//================ends here==================================


case "Inventory_fetch_success":
        
return { ...state, 'InventoryListResponse': action.payload, 'loading':false, 'functionalLoader':false, 'catagoryValue':false };



  case "images_fetch_success_Inventory":
  return { ...state, 'InventoryImagesResponse': action.payload, 'functionalLoader':false, 'loading':false};
  
  case "storeInventorysImages_success":
    return { ...state, 'autosImagesUploadResponse': action.payload, 'loading':false,  'functionalLoader':false,  'cleanAdditionalValues':true };
  
  

           

              case "filterOnWarehouses_success":
        
                return { ...state, 'autosListResponse': action.payload, 'loading':false,  'functionalLoader':false, 'catagoryValue':true };
          
           
      case "getInventoryAdditionalReg_success":

        return { ...state, 'getAdditionalRegDataSuccess': action.payload, 'loading':false, 'functionalLoader':false, };


        case "changePointOfLoading_success":

          return { ...state, 'pointOfLoadingChangeSuccess': action.payload, 'loading':false,'functionalLoader':false, 'openInventoryEditModal':false, 'currentPage':action.payload.currentPage  };
  
  
        

         case "storeInventoryData_success":
        
         return { ...state, 'autoRegistrationSuccess': action.payload, 'loading':false , 'functionalLoader':false,'InventoryRegistrationErrors':null,'openInventoryModal':false, 'cleanValues':true  };
       

         case 'upload_progress':
          return { ...state, 'uploadPercentage':action.payload };

          
          case 'resetProgressBar':
            return { ...state, 'uploadPercentage':0 };

         case "autoAdditionalReg_success":
        
          return { ...state, 'autoAdditionalRegistrationSuccess': action.payload, 'loading':false , 'functionalLoader':false, 'openInventoryAdditionalModal':false, 'currentPage':action.payload.currentPage, 'autoRegistrationErrors':null, 'cleanAdditionalValues':true, 'uploadPercentage':100 };
    
       case 'images_fetch_success_autos':
       return { ...state, 'getImagesSuccess': action.payload, 'loading':false, 'functionalLoader':false,  };

      
          case "storeInventoryData_failure":
      
         return { ...state, 'InventoryRegistrationErrors': action.payload, 'loading':false ,'functionalLoader':false,};
          
    


          case "roleDeletion_success":
          
          return { ...state, 'roleDeletedResponse': action.payload, 'currentPage':action.payload.currentPage};

          case 'imagesDeletion_success':

          return { ...state, 'imagesDeletionSuccess': action.payload, 'loading':false, 'functionalLoader':false,};

          case "autoDelete_success":
            return { ...state, 'autoDeleted': action.payload, 'loading':false, 'functionalLoader':false,};

            case "selectedInventorys_success":
              return { ...state, 'selectedInventorysDeleted': action.payload, 'loading':false, 'functionalLoader':false, 'selectedArray':''};
  
  

// =================================================
// Inventorys communcation reducers
// =================================================

              case "getCommunicationInventory_success":
                return { ...state, 'getInventoryListCommunication': action.payload, 'functionalLoader':false,  'loading':false };
              
              
              case "autoCommunication_success":
                return { ...state, 'registerNoteResponse': action.payload, 'functionalLoader':false, 'loading':false, 'textAreaValue':'' };
              
              
              case "deleteSelectedInventoryNotes_success":
                return { ...state, 'deletedSelectedNotes': action.payload, 'functionalLoader':false, 'loading':false, 'textAreaValue':'' };
              case "getEditInventoryNoteDetails_success":
                return { ...state, 'getEditInventoryNoteInfo': action.payload, 'functionalLoader':false, 'loading':false};
              
              
              case "editSelectedInventoryNotes_success":
                return { ...state, 'editedSelectedNote': action.payload, 'functionalLoader':false, 'loading':false,'openEditNoteAuto':false, 'attachmentValue':''  };
              





case "usersSelectorList_success":

  return { ...state, 'usersSelectorListResponse': action.payload, 'functionalLoader':false, 'loading':false,};

//for searching users list 
case "searchAutos_success":
       
  return { ...state, 'InventorysListResponse': action.payload,'functionalLoader':false,  'loading':false, 'currentPage':action.payload.data.current_page };



    

    
       default:
         return state;
     }
   };
   