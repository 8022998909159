


// export const can = (currentUserPermissions, permission) => {
//     return (currentUserPermissions || []).includes(permission);
// };


export const can = (currentUserPermissions=[], ...permission) => {
    if (!Array.isArray(currentUserPermissions) || currentUserPermissions.length === 0) {
        return false;
    }
    return permission.some(role => currentUserPermissions.includes(role));
};

export const hasRole = (currentUserRole, role) => {
    return (currentUserRole || []).includes(role);
};

// export const hasAnyRole = (currentUserRoles, ...roles) => {
//     return roles.some(role => currentUserRoles.includes(role));
// };


export const hasAnyRole = (currentUserRoles = [], ...roles) => {
    // Ensure roles are always an array and check if any role matches
    if (!Array.isArray(currentUserRoles) || currentUserRoles.length === 0) {
        return false;
    }
    return roles.some(role => currentUserRoles.includes(role));
};

export const isGuest = (currentUserRoles = []) => {
    return !Array.isArray(currentUserRoles) || currentUserRoles.length === 0;
};