const InitialStates = {
functionalLoader:false,
loading:false,
currentPage:1,
openContainerRegistrationModal:false,
openContainerAutoAddition:false,
nullValues:false,
openContainerEdit:false,
searchContainers:'',
containersCreationErrors:null,

   };
   
   export default (state = InitialStates, action) => {
     switch (action.type) {
      case 'inputHandler':  
      return  {...state, [action.payload.key]: action.payload.value};

     
       case "openContainerRegModal":
      
         return { ...state, 'openContainerRegistrationModal': true  };
   
         case "hideContainerRegModal":
           return { ...state, 'openContainerRegistrationModal': false, containersCreationErrors:null};

           case "openContainerAutoAdditionModal":
           return { ...state, 'openContainerAutoAddition': true};

           case "hideContainerAutoAdditionModal":
            return { ...state, 'openContainerAutoAddition': false};

            case "openContainerEditModal":
              return { ...state, 'openContainerEdit': true};
   
              case "hideContainerEditModal":
               return { ...state, 'openContainerEdit': false ,containersUpdateErrors:null};



//=============================
//for loading purposes
//=============================



case "Containers_fetch_started":
        
  return { ...state, 'loading':true };

case "ContainerReg_started":
        
  return { ...state, 'functionalLoader':true
 };

case "ContainerUpdate_started":
        
  return { ...state, 'functionalLoader':true
 };



    case "storeContainerData_started":
        
      return { ...state, 'functionalLoader':true
 };

   case "storeContainerImages_started":
        
      return { ...state, 'functionalLoader':true
 };

   case "containerImagesDeletion_started":
        
        return { ...state, 'functionalLoader':true
 };


   case "ContainerDelete_started":
        
          return { ...state, 'loading':true };
  

   case "searchContainer_started":
        
              return { ...state, 'loading':true };


   case "selectedContainers_started":
        
          return { ...state, 'functionalLoader':true};

     case "selectedAutosContainersDelete_started":
        
          return { ...state, 'functionalLoader':true};


  case "containerAddAutos_started":
        
    return { ...state, 'functionalLoader':true
 };

//   case "zipImages_started":
        
//   return { ...state, 'functionalLoader':true
//  };


// =====Loaders ends here  =============

           case "VinNumberFetch_success":

            return { ...state, 'VinNumberFetchResponse': action.payload, 'functionalLoader':false,  'loading':false,};
          
         case "Containers_fetch_success":
          return { ...state, 'containersListResponse': action.payload,'functionalLoader':false,   'loading':false,};

     
         case "preData_success":
        
         return { ...state, 'preDataLoadResponse': action.payload,  'preLoading':false };
   
         case "images_container_fetch_success":
          return { ...state, 'containerImagesResponse': action.payload, 'functionalLoader':false,  'loading':false };

   
case "containerAddAutos_success":
  return { ...state, 'contAddAutosResponse': action.payload, 'functionalLoader':false,  'loading':false, openContainerAutoAddition:false, nullValues:true, 'currentPage':action.payload.currentPage };

  case "ContainerReg_success":
    return { ...state, 'contAutosRegResponse': action.payload, 'functionalLoader':false,  'loading':false, openContainerRegistrationModal:false, 'currentPage':action.payload.currentPage, nullValues:true };
  
  case "selectedAutosContainersDelete_success":
    return { ...state, 'containerSelectedAutosDeleteResponse': action.payload, 'functionalLoader':false, 'loading':false , nullValues:true};

case "getContainerDetails_success":
  return { ...state, 'containerDetailsResponse': action.payload,'functionalLoader':false,   'loading':false  };

  case "ContainerUpdate_success":
    return { ...state, 'containerUpdateResponse': action.payload, 'functionalLoader':false, 'loading':false, openContainerEdit:false, 'currentPage':action.payload.currentPage };

case "containterImagesDeletion_success":
  return { ...state, 'containerImagesDeletionResponse': action.payload, 'functionalLoader':false, 'loading':false  };

case "storeContainerImages_success":
  return { ...state, 'containerImagesUploadResponse': action.payload,'functionalLoader':false,  'loading':false, nullValues:true };

  case "ContainerDelete_success":
    return { ...state, 'containerDeleteResponse': action.payload,'functionalLoader':false,  'loading':false  };
case "selectedContainers_success":
  return { ...state, 'selectedDeletionContainerResponse': action.payload,'functionalLoader':false,  'loading':false  };


case "searchContainer_success":
  return { ...state, 'containersListResponse': action.payload,'functionalLoader':false,  'loading':false  };

case "ContainerReg_failure":
  return { ...state, 'containersCreationErrors': action.payload,'functionalLoader':false,  'loading':false  };
case "ContainerUpdate_failure":
  return { ...state, 'containersUpdateErrors': action.payload, 'functionalLoader':false, 'loading':false  };

  // case "zipImages_success":
  //   return { ...state,  'functionalLoader':false, 'loading':false  };


       default:
         return state;
     }
   };
   