import React, { useRef, useEffect } from 'react';
import QRCode from 'qrcode';
import CryptoAES from 'crypto-js/aes';

const QrCodeGenerator = ({ id }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    // Encrypt the id and create the link
    const encryptedId = CryptoAES.encrypt(id.toString(), 'autoListGhulam_123').toString();
    const url = `/home/autoView/${encodeURIComponent(encryptedId)}`;

    // Generate the QR code with the encrypted URL
    QRCode.toCanvas(canvasRef.current, url, {
      width: 220,
      color: {
        dark: '#000000',  // QR code foreground color
        light: '#ffffff', // QR code background color
      },
    });
  }, [id]);

  return (
    <div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default QrCodeGenerator;
