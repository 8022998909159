import axios from "axios";
import Swal from "sweetalert2";
// import History from "../../routes/CustomHistory";
import moment from "moment";

var CryptoJS = require("crypto-js");




// -------------------------------------------------------------------------
// for opening and closing ContainerInvoice settings model
// -------------------------------------------------------------------------
export const showContainerInvoiceRegModal=()=>{
  return {
    type:'openinvoiceRegistrationModal'
  }
  }

  export const hideContainerInvoiceRegModal=()=>{
    return {
      type:'hideinvoiceRegistrationModal'
    }
    }

    

export const invoiceInformationModalToggle=()=>{
return {
type:'invoiceInformationModal'
}
}

export const hideContainerInvoiceInfoModal=()=>{
return {
  type:'hideInvoiceInformationModal'
}
}

export const showContainerInvoiceInfoEditModal=()=>{
  return {
    type:'openInvoiceInfoEditModal'
  }
  }

  export const hideContainerInvoiceInfoEditModal=()=>{
    return {
      type:'hideInvoiceInfoEditModal'
    }
    }

    export const showContainerInvoiceBillerEditModal=()=>{
      return {
        type:'openInvoiceBillerEditModal'
      }
      }
    
      export const hideContainerInvoiceBillerEditModal=()=>{
        return {
          type:'hideInvoiceBillerEditModal'
        }
        }
    




// ---------------------------------------------------------------------------------------------------------
// to get all ContainerInvoices for settings
// ---------------------------------------------------------------------------------------------------------

export const getContainerInvoices = (id) => {



    return (dispatch) => {
        dispatch(getContainerInvoiceStarted());
    
        const cipherToken = JSON.parse(localStorage.getItem("fkey"));
    
        var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
        var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    
        //console.log(decryptedToken);
    
       
        let axiosConfig = {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Accept: "application/json",
            Authorization: `Bearer ${decryptedToken}`,
          },
  
        };
       
        // console.log(id)
        axios
          .get(`${process.env.REACT_APP_API_ADDRESS}/ContainerInvoiceDetailsFetch/`+id,axiosConfig)
        
          .then((response) => {
            if (response.data.success_message) {
              // console.log(response.data);
              dispatch(getContainerInvoiceSuccess(response.data));
          
            } else if(response.data.error_message){
              //console.log(response.data);
              dispatch(getContainerInvoiceSuccess(response.data));
            }
          })
          .catch((err) => {
            //console.log(err.response.data);
            dispatch(getContainerInvoiceFailure(err.response.data.errors));
  
          });
      };
    };
    
    const getContainerInvoiceStarted = () => ({
      type: "getContainerInvoice_started",
    });
    
    
    const getContainerInvoiceSuccess = (data) => ({
      type: "getContainerInvoices_success",
      payload: {
        ...data,
    },
    });
    
    const getContainerInvoiceFailure = (error) => ({
      type: "getContainerInvoice_failure",
      payload: {
        error,
      }, 
    });
  
  
  


// ---------------------------------------------------------------------------------------------------------
// roles register in the system with pagination Action - the authenticated role would be able to fetch list here
// ---------------------------------------------------------------------------------------------------------



export const registerContainerInvoice = (data,vinDataInfo) => {
  
  return (dispatch) => {
    dispatch(registerContainerInvoiceStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let ContainerInvoiceData={
    containers_id:data.containers_id,
    bill_to:data.bill_to?.value,
    vinDataInfo:vinDataInfo,


    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/ContainerInvoiceRegister`, ContainerInvoiceData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       console.log(response.data)
          dispatch(registerContainerInvoiceSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The invoice has been succesfully created',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
        }
      })
      .catch((err) => {
      
        dispatch(registerContainerInvoiceFailure(err.response.data.errors));
     
      });
  };
};

const registerContainerInvoiceStarted = () => ({
  type: "registerContainerInvoice_started",
});

const registerContainerInvoiceSuccess = (data) => ({
  type: "registerContainerInvoice_success",
  payload: {
    ...data,
  },
});

const registerContainerInvoiceFailure = (error) => ({
  type: "registerContainerInvoice_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// just to set the print date in the database Print date
// ---------------------------------------------------------------------------------------------------------



export const invoicePrintContainer = (id) => {
  //console.log(id)
  return (dispatch) => {
    dispatch(invoicePrintStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };

    let ContainerInvoiceData={
    id:id
    }

    axios
      .post(`${process.env.REACT_APP_API_ADDRESS}/invoicePrint`, ContainerInvoiceData, axiosConfig)
      .then((response) => {
        if (response.data.success_message) {

       //console.log(response.data)
          dispatch(invoicePrintSuccess(response.data));

        }
      })
      .catch((err) => {
      
        dispatch(invoicePrintFailure(err.response.data.errors));
     
      });
  };
};

const invoicePrintStarted = () => ({
  type: "invoicePrint_started",
});

const invoicePrintSuccess = (data) => ({
  type: "invoicePrint_success",
  payload: {
    ...data,
  },
});

const invoicePrintFailure = (error) => ({
  type: "invoicePrint_failure",
  payload: {
    error,
  },
});




// ---------------------------------------------------------------------------------------------------------
// this will fetch the information of Container invoice, like calculations etc inside bill
// ---------------------------------------------------------------------------------------------------------

export const ContainerInvoiceEditInfo = (id) => {

  return (dispatch) => {
    dispatch(ContainerInvoiceEditInfoDetailsStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editContainerInvoiceInfo/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(ContainerInvoiceEditInfoDetailsSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(ContainerInvoiceEditInfoDetailsFailure(err.response.data.errors));
     
      });
  };
};

const ContainerInvoiceEditInfoDetailsStarted = () => ({
  type: "ContainerInvoiceEditInfoDetails_started",
});

const ContainerInvoiceEditInfoDetailsSuccess = (data) => ({
  type: "ContainerInvoiceEditInfoDetails_success",
  payload: {
    ...data,
  },
});

const ContainerInvoiceEditInfoDetailsFailure = (error) => ({
  type: "ContainerInvoiceEditInfoDetails_failure",
  payload: {
    error,
  },
});



// ---------------------------------------------------------------------------------------------------------
// the ContainerInvoice update 
// ---------------------------------------------------------------------------------------------------------

export const ContainerInvoiceUpdateInfo = (id,invoice_catagory,notes,amount) => {
    return (dispatch) => {
      dispatch(ContainerInvoiceInfoUpdateStarted());
  
      const cipherToken = JSON.parse(localStorage.getItem("fkey"));
  
      var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
      var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  
      // //console.log(decryptedToken);
  
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Accept: "application/json",
          Authorization: `Bearer ${decryptedToken}`,
        },
      };
     
      let warhouseData={
        invoice_catagory:invoice_catagory.value,
        notes:notes,
        amount:amount,
        
       }
  
  
      axios
        .put(`${process.env.REACT_APP_API_ADDRESS}/updateContainerInvoiceInfo/`+id,warhouseData,axiosConfig)
        .then((response) => {
          if (response.data.success_message) {
            //console.log(response.data);
            dispatch(ContainerInvoiceInfoUpdateSuccess(response.data));
  
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              text: 'The Container invoice has been successfully updated ',
              showConfirmButton: false,
              timer: 800,
              toast: true,
  
            })
        
          } 
        })
        .catch((err) => {
          //console.log(err.response.data.errors);
          dispatch(ContainerInvoiceInfoUpdateFailure(err.response.data.errors));
       
        });
    };
  };
  
  const ContainerInvoiceInfoUpdateStarted = () => ({
    type: "ContainerInvoiceInfoUpdate_started",
  });
  
  
  const ContainerInvoiceInfoUpdateSuccess = (data) => ({
    type: "ContainerInvoiceInfoUpdate_success",
    payload: {
      ...data,
    },
  });
  
  const ContainerInvoiceInfoUpdateFailure = (error) => ({
    type: "ContainerInvoiceInfoUpdate_failure",
    payload: {
      error,
    }, 
  });
  





// ---------------------------------------------------------------------------------------------------------
// ContainerInvoices Deletion
// ---------------------------------------------------------------------------------------------------------

export const ContainerInvoiceDelete = (id) => {

  return (dispatch) => {
    dispatch(ContainerInvoiceDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteContainerInvoiceInfo/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          // //console.log(response.data)

          dispatch(ContainerInvoiceDeletionSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Container invoice description has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(ContainerInvoiceDeletionFailure(err.message));
      });
  };
};

const ContainerInvoiceDeletionStarted = () => ({
  type: "ContainerInvoiceDeletion_started",
});

const ContainerInvoiceDeletionSuccess = (data) => ({
  type: "ContainerInvoiceDeletion_success",
  payload: {
    ...data,
  },
});

const ContainerInvoiceDeletionFailure = (error) => ({
  type: "ContainerInvoiceDeletion_failure",
  payload: {
    error,
  },
});






// ---------------------------------------------------------------------------------------------------------
// this will fetch biller info
// ---------------------------------------------------------------------------------------------------------

export const ContainerInvoiceEditBillerInfo = (id) => {

  return (dispatch) => {
    dispatch(ContainerInvoiceEditBillerStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/editContainerInvoiceBiller/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(ContainerInvoiceEditBillerSuccess(response.data));
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(ContainerInvoiceEditBillerFailure(err.response.data.errors));
     
      });
  };
};

const ContainerInvoiceEditBillerStarted = () => ({
  type: "ContainerInvoiceEditBiller_started",
});

const ContainerInvoiceEditBillerSuccess = (data) => ({
  type: "ContainerInvoiceEditBiller_success",
  payload: {
    ...data,
  },
});

const ContainerInvoiceEditBillerFailure = (error) => ({
  type: "ContainerInvoiceEditBiller_failure",
  payload: {
    error,
  },
});





// ---------------------------------------------------------------------------------------------------------
// the ContainerInvoice update 
// ---------------------------------------------------------------------------------------------------------

export const ContainerInvoiceBillerUpdateInfo = (id,biller) => {
  //note id in here is container id for bills
  return (dispatch) => {
    dispatch(ContainerInvoiceBillerUpdateStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
    let warhouseData={
      id:id,
      billers_id:biller?.value||'',
     }


    axios
      .put(`${process.env.REACT_APP_API_ADDRESS}/updateContainerInvoiceBillerInfo/`+id,warhouseData,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          //console.log(response.data);
          dispatch(ContainerInvoiceBillerUpdateSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Container invoice has been successfully updated ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        //console.log(err.response.data.errors);
        dispatch(ContainerInvoiceBillerUpdateFailure(err.response.data.errors));
     
      });
  };
};

const ContainerInvoiceBillerUpdateStarted = () => ({
  type: "ContainerInvoiceBillerUpdate_started",
});


const ContainerInvoiceBillerUpdateSuccess = (data) => ({
  type: "ContainerInvoiceBillerUpdate_success",
  payload: {
    ...data,
  },
});

const ContainerInvoiceBillerUpdateFailure = (error) => ({
  type: "ContainerInvoiceBillerUpdate_failure",
  payload: {
    error,
  }, 
});







// ---------------------------------------------------------------------------------------------------------
// ContainerInvoices Deletion
// ---------------------------------------------------------------------------------------------------------

export const ContainerInvoiceOriginDelete = (id) => {

  return (dispatch) => {
    dispatch(ContainerInvoiceOriginDeletionStarted());

    const cipherToken = JSON.parse(localStorage.getItem("fkey"));

    var bytes = CryptoJS.AES.decrypt(cipherToken, "Ghulamm@2022");
    var decryptedToken = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    // //console.log(decryptedToken);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: `Bearer ${decryptedToken}`,
      },
    };
   
   


    axios
      .delete(`${process.env.REACT_APP_API_ADDRESS}/deleteContainerInvoiceOrigin/`+id,axiosConfig)
      .then((response) => {
        if (response.data.success_message) {
          
          //console.log(response.data)

          dispatch(ContainerInvoiceOriginDeletionSuccess(response.data));

          Swal.fire({
            position: 'top-end',
            icon: 'success',
            text: 'The Container invoice has been successfully deleted ! ',
            showConfirmButton: false,
            timer: 800,
            toast: true,

          })
      
        } 
      })
      .catch((err) => {
        dispatch(ContainerInvoiceOriginDeletionFailure(err.message));
      });
  };
};

const ContainerInvoiceOriginDeletionStarted = () => ({
  type: "ContainerInvoiceOriginDeletion_started",
});

const ContainerInvoiceOriginDeletionSuccess = (data) => ({
  type: "ContainerInvoiceOriginDeletion_success",
  payload: {
    ...data,
  },
});

const ContainerInvoiceOriginDeletionFailure = (error) => ({
  type: "ContainerInvoiceOriginDeletion_failure",
  payload: {
    error,
  },
});


