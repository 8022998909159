import React,{Component} from "react";
import "../../../css/PrintCss/autoPrint.css";
import Logo from "../../assets/GA-01.png";
import { connect } from 'react-redux';
import moment from 'moment'
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/esm/CloseButton";
import Select from "react-select";
import {can} from '../../utils/roleUtils';
import {
  ContainerInvoiceDelete
} from "../../../redux/actions";
// import image from "../../test_image.png";

 class ContainerBill extends Component{
  constructor(props) {
    super(props);
    this.state={
      info_id:'',
      containers_id:'',
      invoice_catagory:'',
      amount:'', 
      notes:'',
      bill_to:'',
      billerOptions:null,
     
    }
  }

  canvasEl;

  // componentDidMount(){




  // }

 billCatagory = [
    { value: 'Credit', label: 'Credit' },
    { value: 'Debit', label: 'Debit' },
   
  ]

 menuPortal={
    menuPortal: base => ({ ...base, zIndex: 9999 })
  }



  
  componentDidUpdate(prevProps, prevState) {
    //this is for setting the edit model
    if(this.props.invoiceEditInfo !==prevProps.invoiceEditInfo){
       this.setState({
    invoice_catagory:{value:this.props.invoiceEditInfo?.data.invoice_catagory,label:this.props.invoiceEditInfo?.data.invoice_catagory},

    amount:this.props.invoiceEditInfo?.data?.amount,
    notes:this.props.invoiceEditInfo?.data?.notes,

    })
    }


    if(this.props.invoiceBillerEditResponse !== prevProps.invoiceBillerEditResponse){
      this.setState({
    
        bill_to:{value:this.props?.invoiceBillerEditResponse?.data?.billers_id,label:this.props?.invoiceBillerEditResponse?.data?.biller_name}
    
        })

    }



   if(this.props.containerDeleteInfoBill !== prevProps.containerDeleteInfoBill){
      this.props.getContainerInvoices(this.props.ID);
    }


  if(this.props.containerPrintResponse !== prevProps.containerPrintResponse){

      this.props.getContainerInvoices(this.props.ID);

    }


    if(this.props.invoiceInfoUpdationResponse !== prevProps.invoiceInfoUpdationResponse){
      this.props.getContainerInvoices(this.props.ID);

    }

  

    if(this.props.invoiceBillerUpdateResponse !== prevProps.invoiceBillerUpdateResponse){
      this.props.getContainerInvoices(this.props.ID);

    }


}

// the component did update function ends here


  billersOptions = this.props?.SelectorsFetch?.billers?.map(function (data) {
    return { value: data?.id, label: data?.biller_name }
  })







 submitContainerInvoice=(e)=>{
  e.preventDefault();
  if(this.state.invoice_catagory==""){
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Please select the type of invoice !",
      timer: 2000,
      timerProgressBar: true,
      toast: true,
      confirmButtonColor: "#0a4b85",
    });
  }else{
    let id = this.state.info_id;
 let invoice_catagory = this.state.invoice_catagory;
 let notes = this.state.notes;
 let amount = this.state.amount;

    this.props.ContainerInvoiceUpdateInfo(id,invoice_catagory,notes,amount);
  
  }
  
  
  }
  
  


  submitInvoiceBiller=(e)=>{
  e.preventDefault();
  if(this.state.bill_to==""){
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Please select the biller name of invoice !",
      timer: 2000,
      timerProgressBar: true,
      toast: true,
      confirmButtonColor: "#0a4b85",
    });
  }else{
 
 let bill_to = this.state.bill_to;
 let id = this.props.ID;


    this.props.ContainerInvoiceBillerUpdateInfo(id,bill_to);
  
  }
  
  
  }


  ContainerInvoiceBillerUpdateInfo





 onInputChangeBill=(e)=>{
this.setState({...this.state,[e.target.name]:e.target.value});
  
  }

handleInvoiceInfoClose=()=>{
  this.props.hideEditInfo()
}

handleInvoiceEditBillerClose=()=>{
  this.props.hideContainerInvoiceBillerEditModal()
}






 theme = (theme) => ({
  ...theme,
  spacing: {
    ...theme.spacing,
    controlHeight: 40,
    baseUnit: 4,
  },
});

// can=(permission)=>(this.props.preDataLoadResponse?.currentUserPermissions||[]).find((p)=>p==permission)?true:false;


 handleBillSelector = (e,selectorName) => {
  // console.log(autoRegistrationData)
let selectName = selectorName.name.toString();
// setBillInfo({ ...billInfo, [selectName]:e });

this.setState({...this.state,[selectName]:e})
};

  // componentDidMount() {
  //   const ctx = this.canvasEl.getContext("2d");
  //   if (ctx) {
  //     ctx.beginPath();
  //     ctx.arc(95, 50, 40, 0, 2 * Math.PI);
  //     ctx.stroke();
  //     ctx.fillStyle = "rgb(200, 0, 0)";
  //     ctx.fillRect(85, 40, 20, 20);
  //     ctx.save();
  //   }
  // }

  // handleCheckboxOnChange = () =>
  //   this.setState({ checked: !this.state.checked });

  setRef = (ref) => (this.canvasEl = ref);

  render() {
    const { responseContainerInvoice } = this.props;

    return (
      <div className="relativeCSS">
        <style type="text/css" media="print">
          {"\
   @page { size: potraite; }\
"}
        </style>
        <div className="customContainer">
          <div>
            {" "}
            <img
            className="inPrint"
              src={Logo}
              style={{
                position: "absolute",
                top: "40%",
                left: "33%",
                width: "290px",
                height: "210px",
                float: "left",
                opacity: 0.1,
                zIndex: 1,
              }}
            />
          </div>
          <div style={{ height: "30px" }}></div>
          <div className="row">
            <div className="col-3">
              {" "}
              <img
              className="invoice-logo"
                src={Logo}
                style={{ width: "130px", height: "80px", float: "left" }}
              />
            </div>
            <div className="col leftLine">
              <div><span className="top-text">Canada</span></div>
              <div><span className="after-top-text">2235 hurontario st, Mississauga, ON</span></div>
              <div><span className="after-top-text">Toll Free:  800-939-0355</span></div>

            </div>
            <div className="col leftLine">
              <div><span className="top-text">Jordan</span></div>
              <div><span className="after-top-text">9th street, free zone, zarqa</span></div>
              <div><span className="after-top-text">Toll Free:  +962797841136</span></div>

            </div>

            <div className="col leftLine">
              <div><span className="top-text">United Arab Emirates</span></div>
              <div><span className="after-top-text">industry area 12, Sharjah</span></div>
              <div><span className="after-top-text">Toll Free:  +971 56 161 8117</span></div>

            </div>
          </div>
          <div className="row">
            <div className="col-3"></div>
            <div className="col">
              <span className="subHeadertext">Web: </span>
              <span className="subHeadertext">www.ghulamautos.com</span>
            </div>
            <div className="col">
              <span className="subHeadertext">Email: </span>
              <span className="subHeadertext">info@ghulamautos.com</span>
            </div>
            <div className="col"></div>

          </div>
          <div className="row">
            <div className="col">
              <div className="horizentalLine"></div>
            </div>
          </div>

          <div className="row">
          <div className="col">
          <div className="date-text"><span className="titleInPrint">Invoice No: </span><span>G-{responseContainerInvoice?.invoice_info?.id}</span></div>

             {responseContainerInvoice?.invoice_info?.print_date? <div className="date-text"><span>Print Date: </span><span>{moment(responseContainerInvoice?.invoice_info?.print_date).format('DD-MM-YYYY')}</span></div>:''}
              {/* <div><span>Due Date: </span><span>sdf</span></div> */}

            </div>
            <div className="col">
              <div className="invoice-text">Invoice</div>
            </div>
          </div>
          <div className="row">
     
            <div className="col">
              <div className="bank-text">Bank Details</div>
            </div>
          </div>

          <div className="row brd">
            <div className="col-8">
              <div><span className="titleInPrint">Beneficiary:</span> <span>Ghulam Auto Inc</span></div>
              <div><span className="titleInPrint">Beneficiary Address:</span> <span>2235 Hurontario St, Mississauga, ON, L5A 2G1, Canada</span></div>
              <div><span className="titleInPrint">Bank Name:</span> <span>TD Canada Trust</span></div>
              <div><span className="titleInPrint">Bank Address:</span> <span>1210 London Road, Sarnia, ON, N7S 1P5, Canada. </span></div>
              <div><span className="titleInPrint">SWIFT Code:</span> <span>TDOMCATTTOR</span></div>
             



            </div>

            <div className="col-4">
           
              <div><span className="titleInPrint">ABA Number:</span> <span>026009593</span></div>
              <div><span className="titleInPrint">Institution Number:</span> <span>004</span></div>
              <div><span className="titleInPrint">Branch Number:</span> <span>36242</span></div>
              <div><span className="titleInPrint">(USD) Account number</span> <span>7306552</span></div>
              <div><span className="titleInPrint">(CAD) Account number</span> <span>5239796</span></div>




            </div>



            <div className="row">
     
     <div className="col">
       <div className="bank-text">Bill To</div>
     </div>
   </div>

   <div className="row brd" style={{position:'relative'}}>
   {can(this.props.preDataLoadResponse?.currentUserPermissions,'Create Container Invoice')?
   <span className="text-bill noprint" style={{position:'absolute', top:'-22px',right:'-84px'}} onClick={()=>{
    this.props.ContainerInvoiceEditBillerInfo(this.props.ID)
    this.props.showContainerInvoiceBillerEditModal()
    
    }}>   <i className="fa fa-edit"></i> </span>:""}

     <div className="col-8">
       <div><span className="titleInPrint">Name:</span> <span>{responseContainerInvoice?.invoice_info?.biller_name}</span></div>
       <div><span className="titleInPrint">Contact:</span> <span>{responseContainerInvoice?.invoice_info?.biller_contact}</span></div>
       <div><span className="titleInPrint">Address:</span> <span>{responseContainerInvoice?.invoice_info?.biller_address}</span></div>

     </div>
</div>
       
          </div>
          <div className="row pt-2">
            <div className="col">
              <div className="horizentalLine2"></div>
            </div>
          </div>
    
     <div className="row">
     
     <div className="col">
       <div className="bank-text">Container Details</div>
     </div>
   </div>



   <div className="row">
            <div className="col">
             <span className="titleInPrint">Container No:</span> <span>{responseContainerInvoice?.containerDetail?.container_no}  </span>
            </div>
     
            <div className="col">
             <span className="titleInPrint">Booking No:</span> <span>{responseContainerInvoice?.containerDetail?.cont_booking_no}</span>
            </div>
           
     
      </div>
      <div className="row">
            <div className="col">
             <span className="titleInPrint">Port:</span> <span>{responseContainerInvoice?.containerDetail?.cont_port}</span>
            </div>
     
            <div className="col">
             <span className="titleInPrint">Loadin Point:</span> <span>{responseContainerInvoice?.containerDetail?.cont_point_loading}</span>
            </div>
           
     
      </div>


      <div className="row pt-2">
            <div className="col">
              <div className="horizentalLine2"></div>
            </div>
          </div>

          <div className="row">
     
     <div className="col">
       <span className="text-descripiton">Description</span>
     </div>
     <div className="col">
       <span className="text-descripiton">Date</span>
     </div>
     <div className="col">
       <span className="text-descripiton">vin</span>
     </div>
     <div className="col">
       <span className="text-descripiton">Notes</span>
     </div>
     <div className="col cent">
       <span className="text-descripiton">Amount</span>
     </div>
   
   </div>
{/* //here starts maping area */}
{responseContainerInvoice?.data?.map((list,index)=>{
return (
  <div key={index}>
   <div className="row">
     
     <div className="col">
       <span className="text-bill">{list.invoice_catagory}</span>
     </div>
     <div className="col">
       <span className="text-bill">{moment(list.created_at).format('DD-MM-YYYY')}</span>
     </div>
     <div className="col">
       <span>{list.vin}</span>
     </div>
     <div className="col">
       <span>{list.notes}</span>
     </div>
     <div className="col cent">
       <span className="text-bill">{list.currency} {list.amount} </span>
     </div>
     {/* edit and delete button */}
     <div className="col-1 cent noprint" style={{position:'absolute', right:'30px'}}>

     {can(this.props.preDataLoadResponse?.currentUserPermissions,'Create Container Invoice')?

     <span className="text-bill" onClick={
      ()=>{
        this.setState({ info_id:list.id})
        this.props.ContainerInvoiceEditInfo(list.id)
        this.props.showEditInfo()
      }
      
      }>   <i className="fa fa-edit invoice-info-action"></i> </span>:""}

{can(this.props.preDataLoadResponse?.currentUserPermissions,'Create Container Invoice')?
  <span className="text-bill" onClick={()=>{


Swal.fire({
  title: "Are you sure?",
  text: "You want to delete this invoice info!",
  icon: "warning",
  width: 400,
  showCancelButton: true,
  timer: 4000,
  timerProgressBar: true,
  confirmButtonColor: "#3085d6",
  cancelButtonColor: "#d33",
  confirmButtonText: "Yes, delete it!",
}).then((result) => {
  if (result.isConfirmed) {
    this.props.invoiceInfoDelete(list.id)
    }
});

 
        
        }}>   <i className="fa fa-trash invoice-info-action" style={{color:'red'}}></i> </span>:""}

     </div>
   </div>
   <div className="row">
            <div className="col">
              <div className="horizentalLine2"></div>
            </div>
          </div>
</div>
)


})}

{/* //ends here */}
<div className="row pb-3">
            <div className="col">
              <div className="horizentalLine2"></div>
            </div>
          </div>
{responseContainerInvoice?.sum.map((list,index)=>{
  return (

    <div className="row" key={index}>
     
     <div className="col-8 cent">
     </div>
     <div className="col">
       <span className="text-bill">Total {list.invoice_catagory}:</span> 

     </div>
     <div className="col">
       <span>{list.currency} {list.total}</span>

     </div>
     

   </div>
  )
})}


   <div className="row">
     
     <div className="col-8">
   <span className="inPrint">Thank you for your business !</span>

     </div>
     
     <div className="col ">
     <div className="horizentalLine2"></div>

       <span className="text-bill">Net Total:</span> 

     </div>
     <div className="col">
     <div className="horizentalLine2"></div>
     {
   

     <span> {responseContainerInvoice?.sum?.find(l=>l.invoice_catagory=="Credit" || l.invoice_catagory=="Debit" )?.currency} {responseContainerInvoice?.sum?.find(l=>l.invoice_catagory=="Credit")?.total - responseContainerInvoice?.sum?.find(l=>l.invoice_catagory=="Debit")?.total||'0'}</span>

     }
   
  
</div>
     
   </div>
      </div>
<div className="row footer">
<div className="col" >
<div className="footer-note"><span className="inPrint">This is system generated invoice no sign required.</span></div>
</div>

</div>















{/* 
========================================================
Modal for editing the information of the invoice amount etc
======================================================== 
*/}

<Modal show={this.props.openInvoiceEditInfo} onHide={this.handleInvoiceInfoClose} size="md"  >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit Description </Modal.Title>
          <CloseButton variant={"white"} onClick={this.handleInvoiceInfoClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={this.submitContainerInvoice} >
        
          <Modal.Body
            className="modal-body"
            style={{zIndex:'10'}}
          >
          <div className="row">
           

         



           <div className="col-md-12 mb-2">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                Invoice Type
                </label>
                <Select
                  options={this.billCatagory}
                  theme={this.theme}
                  placeholder="invoice type"
                  name="invoice_catagory"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body} 
                  styles={this.menuPortal}
                  value={this.state.invoice_catagory}

                  onChange={(e,selector) => this.handleBillSelector(e,selector)}

                />
                  
              </div>



             



          <div className="col-md-12 mb-4">
                <Form.Floating>
                  <Form.Control
                    id="amount"
                    type="number"
                    name="amount"
                    placeholder=' '
                    onChange={(e)=>{this.onInputChangeBill(e)}}
required
                    value={this.state.amount||''}
                    autoComplete="off"
                    
                  />
                  <label htmlFor="amount">Payment</label>
                </Form.Floating>
              </div>


              {/* <div className="col-md-12 mb-4">
                <Form.Floating>
                  <Form.Control
                    id="bill_to"
                    type="input"
                    name="bill_to"
                    placeholder=' '
                    onChange={(e)=>{onInputChangeBill(e)}}

                    value={billInfo.bill_to||''}
                    autoComplete="off"
                    
                  />
                  <label htmlFor="amount">Bill To</label>
                </Form.Floating>
              </div> */}



              <div className="col-md-12">
                <div className="mb-3">
                  <label
                    htmlFor="notes"
                    className="form-label picker-inputs-lebel"
                  >
                    Invoice Notes
                  </label>
                  <textarea
                    className="form-control"
                    id="notes"
                    name="notes"
                  value={this.state.notes||''}
                    onChange={this.onInputChangeBill}
                  ></textarea>
                </div>
              </div>
            
</div>



            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
         Edit
            </button>
          </Modal.Footer>
        </form>
      </Modal>








{/* 
========================================================
Modal to edit biller name
======================================================== 
*/}

<Modal show={this.props.openInvoiceEditBillerResponse} onHide={this.handleInvoiceEditBillerClose} size="md" >
        <Modal.Header className="modal-header-color">
          <img
            src={Logo}
            style={{ width: "70px", height: "35px", paddingRight: "10px" }}
          />
          <Modal.Title className="modal-header-text">Edit bill to info </Modal.Title>
          <CloseButton variant={"white"} onClick={this.handleInvoiceEditBillerClose}></CloseButton>
        </Modal.Header>

        <form onSubmit={this.submitInvoiceBiller} >
        
          <Modal.Body
            className="modal-body"
            style={{zIndex:'10'}}

          >
          <div className="row">
           

         






              <div className="col-md-12 mb-2">
                <label
                  htmlFor="point_of_loading"
                  className="picker-inputs-lebel"
                >
                Bill To
                </label>
                <Select
                  options={this.billersOptions}
                  theme={this.theme}
                  placeholder="bill to"
                  name="bill_to"
                  isClearable={true}
                  zIndex={1}
                  menuPortalTarget={document.body} 
                  styles={this.menuPortal}
                  value={this.state.bill_to}
                  onChange={(e,selector) => this.handleBillSelector(e,selector)}

                />
                   
              </div>



       


</div>



            {/* <Button text={"Submit"} class={"btn btn-primary"} type={'submit'} /> */}
          </Modal.Body>

          <Modal.Footer className="modal-footer">
            <button className="btn-submit" type="submit">
         Edit
            </button>
          </Modal.Footer>
        </form>
      </Modal>








      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {containerInvoiceResponse,loading} = state.ContainerInvoiceHandler;


  return {
    loading: loading,

 
  };
};





{/* 
========================================================
Modal for invoice view information
======================================================== 
*/}















// const mapDispatchToProps = {
//   ContainerInvoiceDelete
// };



// export default connect(mapStateToProps,mapDispatchToProps) (Bill);


// const ConnectedMyComponent = connect(
//   mapStateToProps
// )(Bill);







export const BillToPrint = React.forwardRef((props, ref) => {
  // eslint-disable-line max-len
  return <ContainerBill ref={ref} responseContainerInvoice={props.responseContainerInvoice} 
   invoiceInfoDelete={props.invoiceInfoDelete}
    getContainerInvoices={props.getContainerInvoices}
     containerDeleteInfoBill={props.containerDeleteInfoBill}
      ID={props.ID}
   containerPrintResponse={props.containerPrintResponse} 
   openInvoiceEditInfo={props.openInvoiceEditInfo} 
    showEditInfo={props.showEditInfo}
    hideEditInfo={props.hideEditInfo} 
    invoiceEditInfo={props.invoiceEditInfo}
      ContainerInvoiceEditInfo={props.ContainerInvoiceEditInfo}
      SelectorsFetch={props.SelectorsFetch}
      openInvoiceEditBillerResponse={props.openInvoiceEditBillerResponse}
      showContainerInvoiceBillerEditModal={props.showContainerInvoiceBillerEditModal}
      hideContainerInvoiceBillerEditModal={props.hideContainerInvoiceBillerEditModal}
      ContainerInvoiceUpdateInfo={props.ContainerInvoiceUpdateInfo}
      invoiceInfoUpdationResponse={props.invoiceInfoUpdationResponse}
      ContainerInvoiceEditBillerInfo={props.ContainerInvoiceEditBillerInfo}
      invoiceBillerEditResponse={props.invoiceBillerEditResponse}
      ContainerInvoiceBillerUpdateInfo={props.ContainerInvoiceBillerUpdateInfo}
      invoiceBillerUpdateResponse={props.invoiceBillerUpdateResponse}
      preDataLoadResponse={props.preDataLoadResponse}


   />;
});

